@use "Library";

@mixin import {
    #adminMinorUpdates {
        @include Library.mainPageFormat;
        #renderedObjects {
            margin-top: 10px;
            .calendarOptions {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 90px;
                border: 1px solid black;
                border-radius: 10px;
                padding: 5px;
                p {margin: 0;}
            }
            .semesterRow {
                width: 500px;
                display: grid;
                grid-template-columns: 150px 120px 110px 100px;
                border: 1px solid black;
                padding-left: 10px;
            }
            .directorMessages {
                border: 1px solid black;
                border-radius: 8px;
                padding: 3px;
                p {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}