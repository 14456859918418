$SC-color-blue: #003057;
$SC-color-orange: #F47920;
$SC-color-blue-100: hsl(207, 100%, 10%);
$SC-color-blue-200: hsl(207, 100%, 20%);
$SC-color-blue-300: hsl(207, 100%, 30%);
$SC-color-blue-400: hsl(207, 100%, 40%);
$SC-color-blue-500: hsl(207, 100%, 50%);
$SC-color-blue-600: hsl(207, 100%, 60%);
$SC-color-blue-700: hsl(207, 100%, 70%);
$SC-color-blue-800: hsl(207, 100%, 80%);
$SC-color-blue-900: hsl(207, 100%, 90%);
$SC-color-orange-100: hsl(25, 91%, 10%);
$SC-color-orange-200: hsl(25, 91%, 20%);
$SC-color-orange-300: hsl(25, 91%, 30%);
$SC-color-orange-400: hsl(25, 91%, 40%);
$SC-color-orange-500: hsl(25, 91%, 50%);
$SC-color-orange-600: hsl(25, 91%, 60%);
$SC-color-orange-700: hsl(25, 91%, 70%);
$SC-color-orange-800: hsl(25, 91%, 80%);
$SC-color-orange-900: hsl(25, 91%, 90%);
$Talking-Track: rgb(218, 218, 255);

@mixin concertLocationColor {
    .ConcertHall {background-color: #add8e6;border-radius: 5px;}
    .RecitalHall {background-color: #f08080;border-radius: 5px;}
    .BlackBox {background-color: #d3d3d3;border-radius: 5px;}
    .Theatre {background-color: #acf2ac;border-radius: 5px;}
    .Other {background-color: black;border-radius: 5px;color: white;}
}

@mixin mainPageFormat {
    padding: 10px;
    grid-area: b;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 25%;
}

@mixin pButton {
    .pButton {
        background-color: gray;
        color: white;
        padding: 5px;
        margin: 5px;
        border: 2px solid gray;
        border-radius: 25px;
        height: 20px;
        width: 20px;
        z-index: 1;
        cursor: pointer;
        .pButtonImage {
            width: 100%;
            height: 100%;
        }
    }
}

@mixin fullButtons {
    #fullButtons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 100px;
        width: 90%;
        gap: 10px;
        a {text-decoration: none;}
        a, div {
            align-items: center;
            justify-content: center;
            display: flex;
            background-color: $SC-color-orange-700;
            border: 5px solid $SC-color-orange-300;
            border-radius: 20px;
            height: 95%;
            color: black;
            font-size: 20pt;
            cursor: pointer;
        }
    }
}

@mixin halfButtons {
    #fullButtons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 75px;
        width: 90%;
        gap: 10px;
        a {text-decoration: none;}
        a div {
            align-items: center;
            justify-content: center;
            display: flex;
            background-color: $SC-color-orange-700;
            border: 5px solid $SC-color-orange-300;
            border-radius: 20px;
            height: 95%;
            color: black;
            font-size: 20pt;
            @media (max-width: 800px) {
                font-size: 15pt;
            }
        }
    }
}