@use "Library"; @use "RecentConcerts";

@mixin import {
    #pipelinePage {
        @include Library.mainPageFormat;
        @include RecentConcerts.import;
        #inProgressArea {
            border: 2px solid Library.$SC-color-orange-200;
            border-radius: 10px;
            background-color: Library.$SC-color-orange-800;
            padding: 5px;
            margin-bottom: 20px;
            .inProgressItem {
                margin-top: 4px;
                border: 2px solid black;
                border-radius: 5px;
                padding: 5px;
            }
            @include largeBoldLetters;
            @media (max-width: 800px){
                grid-template-columns: 1fr 1fr;
            }
        }
        #uncheckedArea {
            background-color: Library.$SC-color-blue-800;
            padding: 5px;
            border: 2px solid Library.$SC-color-blue-200;
            border-radius: 10px;
            .uncheckedConcert {
                margin-top: 4px;
                border: 2px solid black;
                border-radius: 5px;
                padding: 5px;
            }
            @include largeBoldLetters;
        }
    }
}

@mixin largeBoldLetters {
    b {
        font-size: 20pt;
    }
}