@use "Library";
@use "Home";

@mixin import {
    #singleConcertPage {
        @include Library.mainPageFormat;
        @include Home.individualConcertDisplay;

        #talkingState {
            width: fit-content;

            p {
                padding: 5px;
                cursor: pointer;
                user-select: none;
                border-radius: 20px;
                border: 1px solid Library.$SC-color-blue-100;
            }

            .showing {
                background-color: Library.$SC-color-blue-800;
            }

            .hiding {
                background-color: gray;
                color: white;
            }
        }
    }
}