@use "Library";

@mixin import {
    #lowerThird {
    padding: 5px;
    padding-bottom: 15px;
    margin: 0;
    grid-area: c;
    // background: linear-gradient(Library.$SC-color-orange, #cdcdcd);
    background-color: rgb(72, 72, 72);
    border-top: 5px solid black;
    z-index: 10;
    height: 100%;
    // height: calc(100% - 25px);

    display: flex;
    justify-content: space-around;
    #musicPlayer {
        display: grid;
        height: 70px;
        width: min(100%, 500px);
        grid-template-columns: 100px 1fr 50px 60px;
        grid-template-rows: 20px 20px 30px;
        grid-template-areas: "a a a a"
                             "f b b c"
                             "f d e e";
        border: 2px solid black;
        border-radius: 10px;
        margin: 0 10px;
        padding: 10px 5px;
        background-color: Library.$SC-color-blue-700;
        gap: 2px;
        span {
            padding-left: 2px;
            border: 1px solid black;
            font-size: smaller;
            height: 20px;
        }
        #concertTitle {
            grid-area: b;
            text-wrap: nowrap;
            overflow-x: hidden;
        }
        #timecode {grid-area: c;}
        #trackNumber {grid-area: d;}
        #queueCount {grid-area: e;}
        #buttonSection {
            grid-area: f;
            img {
                height: 30px;
                width: 30px;
                cursor: pointer;
                border: 2px solid Library.$SC-color-blue-100;
                border-radius: 50px;
                padding: 2px;
                margin-left: 6px;
            }
        }
        #audioPlayerSlider {
            grid-area: a;
            background: transparent;
            cursor: pointer;
            width: 100%;
            height: 3px;
            background: red;
        }
        #audioPlayerSlider::-webkit-slider-thumb {
            border: 1px solid #000000;
            height: 36px;
            width: 16px;
            border-radius: 3px;
            background: #ffffff;
            cursor: pointer;
            // margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
            // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
        }

    }
    #concertInfo, #queueNumber {
        padding: 5px;
        color: white;
        p {
            padding: 0;
            margin: 0;
        }
    }
    @media (max-width: 800px) {
        display: flex;
        flex-wrap: wrap;
        height: calc(100% - 25px);
        border-top: none;
        border-bottom: 7px solid black;
        #audioPlayer {width: 100%; margin-top: 5px;}
    }
    @media (max-width: 600px) {
        #queueNumber {display: none;}
    }
    }
}