@use "Library";

@mixin import {
    #recentConcerts {
        border: 2px solid black;
        border-radius: 10px;
        padding: 5px;
        margin-top: 20px;
        #outerInfo {
            border: 1px solid black;
            border-radius: 10px;
            background-color: hsl(0, 0%, 90%);
            display: flex;
            flex-wrap: wrap;
            cursor: pointer;
            p {
                margin-left: 10px;
            }
        }
        #innerInfo {
            padding: 5px;
            border: 1px solid black;
            border-radius: 10px;
            h2 {
                margin-bottom: 5px;
            }
            p {
                margin: 0;
                width: 70%;
                display: flex;
                justify-content: space-between;
                .separator {
                    align-self: flex-end;
                }
                @media (max-width: 800px) {
                    display: block;
                }
            }
        }
    }
}