@use "Library";

@mixin import {
    #directorsPage {
        @include Library.mainPageFormat;
        #pages {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 400px 500px 400px;
            grid-template-areas: "a b"
                                 "c c"
                                 "d d";
            gap: 5px;
            .extraPage {
                overflow: hidden;
                overflow-y: auto;
                border: 2px solid black;
                border-radius: 6px;
                #pipelinePage {
                    padding-bottom: 10px;
                }
                #calendarRequest {
                    padding: 10px;
                }
            }
            .spreadsheet {
                grid-area: c;
            }
            .requestForm {
                grid-area: d;
                padding: 10px;
                input, textarea {
                    width: calc(100% - 5px);
                }
                textarea {
                    height: 120px;
                }
            }
        }
    }
}