@use "Library";

@mixin import {
    #compChallengePage {
        @include Library.mainPageFormat;
        .monthlyChallenge {
            border: 2px solid black;
            border-radius: 20px;
            padding: 7.5px;
            .insideMonthlyChallenge {
                border: 1px solid black;
                border-radius: 10px;
                padding: 5px;
            }
        }
    }
}