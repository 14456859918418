@use "Library"; @use "Home";

@mixin import {
    #concertImportOutside {
        @include Library.mainPageFormat;
        #adminImportInformation {
            background-color: rgb(194, 194, 194);
            border: 2px solid rgb(194, 194, 194);
            border-radius: 10px;
            padding-left: 20px;
            padding-right: 80px;
        }
        @include concertImportForm();
        @include Home.individualConcertDisplay();
        #JSONdisplay {
            width: 100%;
            height: 100px;
            border-radius: 10px;
            background-color: lightcoral;
        }
    }
}

@mixin concertImportForm {
    #concertImport {
        display: flex;
        flex-direction: column;
        justify-content: center;   
        padding-bottom: 40px;
        #fileTitle, #numberOfTracks {
            // margin-bottom: 10px;
            width: fill;
        }
        #tagCheckboxImportForm {
            background-color: rgb(208, 208, 208);
            display: flex;
            flex-wrap: wrap;
            padding-left: 5px;
            margin-left: 10px;
            border: 1px solid rgb(208, 208, 208);
            border-radius: 10px;
            .individualTags {
                display: inline;
                border: 2px solid black;
                border-radius: 5px;
                color: white;
                background-color: rgb(56, 56, 56);
                padding: 3px;
                margin: 5px;
                user-select: none;
                height: fit-content;
            }
            .tagCheckboxImport {
                transform: scale(1.5);
            }
        }
        label, p {
            justify-self: center;
            padding: 5px;
        }
        #bottomCheckboxes {
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            div {
                user-select: none;
            }
        }
        #trackNumberButton {
            margin-bottom: 10px;
        }
        #peopleSection, #mainConcertInfoForm {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 2px;
            margin: 10px;
            div input, div textarea {
                width: 100%;
            }
            label {
                user-select: none;
            }
            #selectForms {
                display: flex;
                flex-wrap: nowrap;
                .selectForm {
                    width: 30%;
                    margin-right: 3px;
                }
            }
            #completedPerformersArea {
                margin: 2px;
                padding: 5px;
                border: 1px solid black;
                border-radius: 2px;
                max-height: 200px;
                width: fit-content;
                overflow: hidden;
                overflow-y: auto;
            }
        }
        #formsArray .trackFormElement {
            padding: 10px;
            background-color: rgb(208, 208, 208);
            display: grid;
            grid-template-columns: 140px 3fr 80px 2fr 90px 0.5fr;

        }
        #redDeleteButton {
            background-color: red;
            margin-top: 30px;
            color: white;
        }
    }
}