@use "Library"; @use "Home";

@mixin import {
    #searchPage {
        @include Library.mainPageFormat;
        #concertArea {
            @include Home.individualConcertDisplay;
            margin-top: 10px;
        }
    }
}