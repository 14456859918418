@use "Library";

@mixin import {
    .reportPopup {
        display: block;
        position: absolute;
        top: calc(50% - 175px);
        left: calc(50% - 163px);
        width: 300px;
        height: 340px;
        background: linear-gradient(180deg, Library.$SC-color-blue-900, Library.$SC-color-blue-700);
        border: 2px solid black;
        border-radius: 10px;
        padding: 10px;
        textarea {
            width: 95%;
            height: 40px;
        }
        z-index: 1000;
        
    }
}