@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  font-family: museo, serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#webpage {
  height: 100vh;
  grid-template-areas: "a b" "c c";
  grid-template-columns: 120px 1fr;
  grid-template-rows: 85vh 15vh;
  display: grid;
}
@media (max-width: 800px) {
  #webpage {
    grid-template-areas: "c" "a" "b";
    grid-template-columns: 1fr;
    grid-template-rows: 110px 60px 10fr;
  }
}
#webpage #lowerThird {
  padding: 5px;
  padding-bottom: 15px;
  margin: 0;
  grid-area: c;
  background-color: rgb(72, 72, 72);
  border-top: 5px solid black;
  z-index: 10;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
#webpage #lowerThird #musicPlayer {
  display: grid;
  height: 70px;
  width: min(100%, 500px);
  grid-template-columns: 100px 1fr 50px 60px;
  grid-template-rows: 20px 20px 30px;
  grid-template-areas: "a a a a" "f b b c" "f d e e";
  border: 2px solid black;
  border-radius: 10px;
  margin: 0 10px;
  padding: 10px 5px;
  background-color: hsl(207, 100%, 70%);
  gap: 2px;
}
#webpage #lowerThird #musicPlayer span {
  padding-left: 2px;
  border: 1px solid black;
  font-size: smaller;
  height: 20px;
}
#webpage #lowerThird #musicPlayer #concertTitle {
  grid-area: b;
  text-wrap: nowrap;
  overflow-x: hidden;
}
#webpage #lowerThird #musicPlayer #timecode {
  grid-area: c;
}
#webpage #lowerThird #musicPlayer #trackNumber {
  grid-area: d;
}
#webpage #lowerThird #musicPlayer #queueCount {
  grid-area: e;
}
#webpage #lowerThird #musicPlayer #buttonSection {
  grid-area: f;
}
#webpage #lowerThird #musicPlayer #buttonSection img {
  height: 30px;
  width: 30px;
  cursor: pointer;
  border: 2px solid hsl(207, 100%, 10%);
  border-radius: 50px;
  padding: 2px;
  margin-left: 6px;
}
#webpage #lowerThird #musicPlayer #audioPlayerSlider {
  grid-area: a;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 3px;
  background: red;
}
#webpage #lowerThird #musicPlayer #audioPlayerSlider::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
#webpage #lowerThird #concertInfo, #webpage #lowerThird #queueNumber {
  padding: 5px;
  color: white;
}
#webpage #lowerThird #concertInfo p, #webpage #lowerThird #queueNumber p {
  padding: 0;
  margin: 0;
}
@media (max-width: 800px) {
  #webpage #lowerThird {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 25px);
    border-top: none;
    border-bottom: 7px solid black;
  }
  #webpage #lowerThird #audioPlayer {
    width: 100%;
    margin-top: 5px;
  }
}
@media (max-width: 600px) {
  #webpage #lowerThird #queueNumber {
    display: none;
  }
}
#webpage #sideBar {
  padding-top: 0;
  height: 100vh;
  grid-area: a;
  background-color: #003057;
}
#webpage #sideBar ul {
  padding-left: 5px;
}
#webpage #sideBar li {
  list-style-type: none;
  padding: 10px;
  margin: 0px 5px 5px 0px;
  background-color: #F47920;
  border: 1px solid black;
  border-radius: 10px;
  align-self: center;
  justify-self: center;
  width: fill;
}
@media (max-width: 800px) {
  #webpage #sideBar li {
    display: inline;
  }
}
#webpage #sideBar :link, #webpage #sideBar :visited {
  color: white;
  text-decoration: none;
}
#webpage #sideBar .secondOrder {
  padding-left: 10px;
}
#webpage #sideBar .navElement {
  display: flex;
  flex-direction: column;
}
#webpage #sideBar .navElement p {
  padding: 0;
  margin: 0;
}
#webpage #sideBar .expandedElements {
  position: absolute;
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
  background-color: #003057;
  padding: 5px;
  padding-top: 7px;
  border: 2px solid #003057;
  border-radius: 10px;
  z-index: 20;
}
#webpage #sideBar .expandedElements .expandedLink {
  z-index: 10000;
}
@media (max-width: 800px) {
  #webpage #sideBar .expandedElements {
    display: none;
  }
}
#webpage #sideBar img {
  padding-top: 10px;
  width: 100%;
}
@media (max-width: 800px) {
  #webpage #sideBar img {
    width: 50px;
    display: inline;
    padding-top: 5px;
  }
}
@media (max-width: 800px) {
  #webpage #sideBar a {
    height: 50px;
  }
}
@media (max-width: 800px) {
  #webpage #sideBar {
    display: flex;
    flex-wrap: nowrap;
    height: 60px;
    overflow-x: auto;
    position: sticky;
    top: 0;
    width: 100%;
    overflow-y: hidden;
  }
}

textarea, input, select, button {
  font-family: Museo;
}

#aboutPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#aboutPage #underline {
  text-decoration: underline;
  cursor: pointer;
}
#aboutPage #copied {
  color: green;
}

#missingPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}

#concertImportOutside {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#concertImportOutside #adminImportInformation {
  background-color: rgb(194, 194, 194);
  border: 2px solid rgb(194, 194, 194);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 80px;
}
#concertImportOutside #concertImport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
}
#concertImportOutside #concertImport #fileTitle, #concertImportOutside #concertImport #numberOfTracks {
  width: fill;
}
#concertImportOutside #concertImport #tagCheckboxImportForm {
  background-color: rgb(208, 208, 208);
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
  margin-left: 10px;
  border: 1px solid rgb(208, 208, 208);
  border-radius: 10px;
}
#concertImportOutside #concertImport #tagCheckboxImportForm .individualTags {
  display: inline;
  border: 2px solid black;
  border-radius: 5px;
  color: white;
  background-color: rgb(56, 56, 56);
  padding: 3px;
  margin: 5px;
  user-select: none;
  height: fit-content;
}
#concertImportOutside #concertImport #tagCheckboxImportForm .tagCheckboxImport {
  transform: scale(1.5);
}
#concertImportOutside #concertImport label, #concertImportOutside #concertImport p {
  justify-self: center;
  padding: 5px;
}
#concertImportOutside #concertImport #bottomCheckboxes {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
#concertImportOutside #concertImport #bottomCheckboxes div {
  user-select: none;
}
#concertImportOutside #concertImport #trackNumberButton {
  margin-bottom: 10px;
}
#concertImportOutside #concertImport #peopleSection, #concertImportOutside #concertImport #mainConcertInfoForm {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2px;
  margin: 10px;
}
#concertImportOutside #concertImport #peopleSection div input, #concertImportOutside #concertImport #peopleSection div textarea, #concertImportOutside #concertImport #mainConcertInfoForm div input, #concertImportOutside #concertImport #mainConcertInfoForm div textarea {
  width: 100%;
}
#concertImportOutside #concertImport #peopleSection label, #concertImportOutside #concertImport #mainConcertInfoForm label {
  user-select: none;
}
#concertImportOutside #concertImport #peopleSection #selectForms, #concertImportOutside #concertImport #mainConcertInfoForm #selectForms {
  display: flex;
  flex-wrap: nowrap;
}
#concertImportOutside #concertImport #peopleSection #selectForms .selectForm, #concertImportOutside #concertImport #mainConcertInfoForm #selectForms .selectForm {
  width: 30%;
  margin-right: 3px;
}
#concertImportOutside #concertImport #peopleSection #completedPerformersArea, #concertImportOutside #concertImport #mainConcertInfoForm #completedPerformersArea {
  margin: 2px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 2px;
  max-height: 200px;
  width: fit-content;
  overflow: hidden;
  overflow-y: auto;
}
#concertImportOutside #concertImport #formsArray .trackFormElement {
  padding: 10px;
  background-color: rgb(208, 208, 208);
  display: grid;
  grid-template-columns: 140px 3fr 80px 2fr 90px 0.5fr;
}
#concertImportOutside #concertImport #redDeleteButton {
  background-color: red;
  margin-top: 30px;
  color: white;
}
#concertImportOutside .reportPopup {
  display: block;
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 163px);
  width: 300px;
  height: 340px;
  background: linear-gradient(180deg, hsl(207, 100%, 90%), hsl(207, 100%, 70%));
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
}
#concertImportOutside .reportPopup textarea {
  width: 95%;
  height: 40px;
}
#concertImportOutside .outerRow {
  display: grid;
  grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
  grid-template-areas: "g a b c d e f";
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  user-select: none;
  overflow: hidden;
  gap: 5px;
  background-color: rgb(235, 235, 235);
}
@media (max-width: 800px) {
  #concertImportOutside .outerRow {
    grid-template-areas: "a b" "c d" "e h" "g f";
    grid-template-columns: 1fr 1fr;
  }
}
#concertImportOutside .outerRow .outerButtons {
  grid-area: g;
}
#concertImportOutside .outerRow .outerButtons .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#concertImportOutside .outerRow .outerButtons .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#concertImportOutside .outerRow .outerInfoCheck {
  align-items: center;
  margin-top: 10px;
  grid-area: f;
}
#concertImportOutside .outerRow .outerInfoCheck p {
  padding: 0 7px 0 7px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0;
  width: 60px;
}
#concertImportOutside .outerRow .outerInfoCheck .greenBox {
  background-color: rgb(126, 195, 126);
}
#concertImportOutside .outerRow .outerInfoCheck .redBox {
  background-color: rgb(245, 134, 134);
}
#concertImportOutside .outerRow:hover {
  background-color: lightgray;
  cursor: pointer;
}
#concertImportOutside .innerRow {
  background-color: rgb(239, 239, 239);
  cursor: default;
  border: 2px dashed black;
  border-radius: 10px;
  padding: 5px;
}
#concertImportOutside .innerRow .playTracksRow {
  display: grid;
  grid-template-columns: 150px 2fr 2fr 2fr 80px;
  background-color: white;
  border-style: groove;
  align-items: center;
}
#concertImportOutside .innerRow .playTracksRow div {
  display: flex;
  padding: 0px 5px;
}
#concertImportOutside .innerRow .playTracksRow div .clickableButton {
  height: 20px;
  width: 20px;
  margin: 20px 0;
  margin-right: 20px;
  cursor: pointer;
}
#concertImportOutside .innerRow .playTracksRow input {
  width: 15px;
}
@media (max-width: 800px) {
  #concertImportOutside .innerRow .playTracksRow {
    display: flex;
    flex-wrap: wrap;
  }
}
#concertImportOutside .innerRow .talking {
  background-color: rgb(218, 218, 255);
}
#concertImportOutside .innerRow .infoArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "a b" "c b";
}
#concertImportOutside .innerRow .infoArea .shareArea {
  margin-left: 10px;
  grid-area: a;
}
#concertImportOutside .innerRow .infoArea .shareArea button {
  margin-bottom: 0;
}
#concertImportOutside .innerRow .infoArea .shareArea img {
  border: 1px solid rgb(164, 164, 164);
  height: 30px;
  cursor: pointer;
}
#concertImportOutside .innerRow .infoArea .innerRowTopButtonsRow {
  margin: 10px;
  display: flex;
}
#concertImportOutside .innerRow .infoArea .innerRowTopButtonsRow .innerRowTopButtons {
  padding: 5px;
  background-color: #003057;
  color: #F47920;
  border: 2px solid black;
  border-radius: 5px;
  margin: 3px;
}
#concertImportOutside .innerRow .infoArea .innerRowTopButtonsRow :link, #concertImportOutside .innerRow .infoArea .innerRowTopButtonsRow :visited {
  text-decoration: none;
}
#concertImportOutside .innerRow .infoArea .performerArea {
  grid-area: b;
  align-self: stretch;
}
#concertImportOutside .innerRow .infoArea .performerArea .performersList {
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid gray;
  margin-bottom: 10px;
  padding-left: 5px;
}
#concertImportOutside .innerRow .infoArea .performerArea .performersList span {
  display: block;
  padding: 2px;
}
@media (max-width: 800px) {
  #concertImportOutside .innerRow .infoArea .performerArea {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min(1fr, 50px);
  }
}
@media (max-width: 500px) {
  #concertImportOutside .innerRow .infoArea {
    grid-template-columns: 1fr;
    grid-template-areas: "b" "a" "c";
  }
}
#concertImportOutside .innerRow .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#concertImportOutside .innerRow .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#concertImportOutside .innerRow .indivConcertDescription {
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
}
#concertImportOutside #JSONdisplay {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  background-color: lightcoral;
}

#admin {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#admin #fullButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 75px;
  width: 90%;
  gap: 10px;
}
#admin #fullButtons a {
  text-decoration: none;
}
#admin #fullButtons a div {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: hsl(25, 91%, 70%);
  border: 5px solid hsl(25, 91%, 30%);
  border-radius: 20px;
  height: 95%;
  color: black;
  font-size: 20pt;
}
@media (max-width: 800px) {
  #admin #fullButtons a div {
    font-size: 15pt;
  }
}
#admin #requestBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
#admin #requestBlock #calendarRequest {
  border: 2px solid black;
  border-radius: 10px;
  padding-left: 5px;
}
#admin #requestBlock #calendarRequest h2 {
  padding-left: 5px;
}
#admin #requestBlock #calendarRequest .calendarCheck {
  margin: 0 7px 10px 0;
  padding: 10px;
  border: 2px solid darkgray;
}
#admin #requestBlock #calendarRequest .calendarCheck input, #admin #requestBlock #calendarRequest .calendarCheck textarea {
  width: 100%;
}
#admin #requestBlock #lessonRequest {
  border: 2px solid black;
  border-radius: 10px;
  padding-left: 5px;
}
#admin #requestBlock #lessonRequest h2 {
  padding-left: 5px;
}
#admin #requestBlock #lessonRequest .lessonCheck {
  margin: 0 7px 10px 0;
  padding: 10px;
  border: 2px solid darkgray;
}
#admin #requestBlock #lessonRequest .lessonCheck input, #admin #requestBlock #lessonRequest .lessonCheck textarea {
  width: 100%;
}
#admin #requestBlock #userRequest {
  border: 2px solid black;
  border-radius: 10px;
  padding-left: 5px;
}
#admin #requestBlock #userRequest h2 {
  padding-left: 5px;
}
#admin #requestBlock #userRequest .userCheck {
  margin: 0 7px 10px 0;
  padding: 10px;
  border: 2px solid darkgray;
}
#admin #requestBlock #userRequest .userCheck input, #admin #requestBlock #userRequest .userCheck textarea {
  width: 100%;
}
#admin #requestBlock #usernameRequest {
  border: 2px solid black;
  border-radius: 10px;
  padding-left: 5px;
}
#admin #requestBlock #usernameRequest h2 {
  padding-left: 5px;
}
#admin #requestBlock #usernameRequest .nameAccept {
  margin: 0 7px 10px 0;
  padding: 10px;
  border: 2px solid darkgray;
}
#admin #requestBlock #usernameRequest .nameAccept input, #admin #requestBlock #usernameRequest .nameAccept textarea {
  width: 100%;
}
#admin #requestBlock #userReports {
  border: 2px solid black;
  border-radius: 10px;
  padding-left: 5px;
}
#admin #requestBlock #userReports h2 {
  padding-left: 5px;
}
#admin #requestBlock #userReports .userReport {
  margin: 0 7px 10px 0;
  padding: 10px;
  border: 2px solid darkgray;
}
#admin #requestBlock #userReports .userReport input, #admin #requestBlock #userReports .userReport textarea {
  width: 100%;
}

#concertPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#concertPage #fullButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 75px;
  width: 90%;
  gap: 10px;
}
#concertPage #fullButtons a {
  text-decoration: none;
}
#concertPage #fullButtons a div {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: hsl(25, 91%, 70%);
  border: 5px solid hsl(25, 91%, 30%);
  border-radius: 20px;
  height: 95%;
  color: black;
  font-size: 20pt;
}
@media (max-width: 800px) {
  #concertPage #fullButtons a div {
    font-size: 15pt;
  }
}
#concertPage #todayCalendarEvent .ConcertHall {
  background-color: #add8e6;
  border-radius: 5px;
}
#concertPage #todayCalendarEvent .RecitalHall {
  background-color: #f08080;
  border-radius: 5px;
}
#concertPage #todayCalendarEvent .BlackBox {
  background-color: #d3d3d3;
  border-radius: 5px;
}
#concertPage #todayCalendarEvent .Theatre {
  background-color: #acf2ac;
  border-radius: 5px;
}
#concertPage #todayCalendarEvent .Other {
  background-color: black;
  border-radius: 5px;
  color: white;
}
#concertPage .concertRow .reportPopup {
  display: block;
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 163px);
  width: 300px;
  height: 340px;
  background: linear-gradient(180deg, hsl(207, 100%, 90%), hsl(207, 100%, 70%));
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
}
#concertPage .concertRow .reportPopup textarea {
  width: 95%;
  height: 40px;
}
#concertPage .concertRow .outerRow {
  display: grid;
  grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
  grid-template-areas: "g a b c d e f";
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  user-select: none;
  overflow: hidden;
  gap: 5px;
  background-color: rgb(235, 235, 235);
}
@media (max-width: 800px) {
  #concertPage .concertRow .outerRow {
    grid-template-areas: "a b" "c d" "e h" "g f";
    grid-template-columns: 1fr 1fr;
  }
}
#concertPage .concertRow .outerRow .outerButtons {
  grid-area: g;
}
#concertPage .concertRow .outerRow .outerButtons .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#concertPage .concertRow .outerRow .outerButtons .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#concertPage .concertRow .outerRow .outerInfoCheck {
  align-items: center;
  margin-top: 10px;
  grid-area: f;
}
#concertPage .concertRow .outerRow .outerInfoCheck p {
  padding: 0 7px 0 7px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0;
  width: 60px;
}
#concertPage .concertRow .outerRow .outerInfoCheck .greenBox {
  background-color: rgb(126, 195, 126);
}
#concertPage .concertRow .outerRow .outerInfoCheck .redBox {
  background-color: rgb(245, 134, 134);
}
#concertPage .concertRow .outerRow:hover {
  background-color: lightgray;
  cursor: pointer;
}
#concertPage .concertRow .innerRow {
  background-color: rgb(239, 239, 239);
  cursor: default;
  border: 2px dashed black;
  border-radius: 10px;
  padding: 5px;
}
#concertPage .concertRow .innerRow .playTracksRow {
  display: grid;
  grid-template-columns: 150px 2fr 2fr 2fr 80px;
  background-color: white;
  border-style: groove;
  align-items: center;
}
#concertPage .concertRow .innerRow .playTracksRow div {
  display: flex;
  padding: 0px 5px;
}
#concertPage .concertRow .innerRow .playTracksRow div .clickableButton {
  height: 20px;
  width: 20px;
  margin: 20px 0;
  margin-right: 20px;
  cursor: pointer;
}
#concertPage .concertRow .innerRow .playTracksRow input {
  width: 15px;
}
@media (max-width: 800px) {
  #concertPage .concertRow .innerRow .playTracksRow {
    display: flex;
    flex-wrap: wrap;
  }
}
#concertPage .concertRow .innerRow .talking {
  background-color: rgb(218, 218, 255);
}
#concertPage .concertRow .innerRow .infoArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "a b" "c b";
}
#concertPage .concertRow .innerRow .infoArea .shareArea {
  margin-left: 10px;
  grid-area: a;
}
#concertPage .concertRow .innerRow .infoArea .shareArea button {
  margin-bottom: 0;
}
#concertPage .concertRow .innerRow .infoArea .shareArea img {
  border: 1px solid rgb(164, 164, 164);
  height: 30px;
  cursor: pointer;
}
#concertPage .concertRow .innerRow .infoArea .innerRowTopButtonsRow {
  margin: 10px;
  display: flex;
}
#concertPage .concertRow .innerRow .infoArea .innerRowTopButtonsRow .innerRowTopButtons {
  padding: 5px;
  background-color: #003057;
  color: #F47920;
  border: 2px solid black;
  border-radius: 5px;
  margin: 3px;
}
#concertPage .concertRow .innerRow .infoArea .innerRowTopButtonsRow :link, #concertPage .concertRow .innerRow .infoArea .innerRowTopButtonsRow :visited {
  text-decoration: none;
}
#concertPage .concertRow .innerRow .infoArea .performerArea {
  grid-area: b;
  align-self: stretch;
}
#concertPage .concertRow .innerRow .infoArea .performerArea .performersList {
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid gray;
  margin-bottom: 10px;
  padding-left: 5px;
}
#concertPage .concertRow .innerRow .infoArea .performerArea .performersList span {
  display: block;
  padding: 2px;
}
@media (max-width: 800px) {
  #concertPage .concertRow .innerRow .infoArea .performerArea {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min(1fr, 50px);
  }
}
@media (max-width: 500px) {
  #concertPage .concertRow .innerRow .infoArea {
    grid-template-columns: 1fr;
    grid-template-areas: "b" "a" "c";
  }
}
#concertPage .concertRow .innerRow .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#concertPage .concertRow .innerRow .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#concertPage .concertRow .innerRow .indivConcertDescription {
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
}
#concertPage .concertHeaderRow {
  display: grid;
  grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
  border: 2px solid rgb(58, 58, 58);
  border-radius: 10px;
  padding: 0px 5px;
  background-color: rgb(58, 58, 58);
  color: white;
}
#concertPage .concertHeaderRow p {
  padding: 4px;
  margin: 0;
}
@media (max-width: 800px) {
  #concertPage .concertHeaderRow {
    grid-template-columns: 1fr 1fr;
  }
  #concertPage .concertHeaderRow .notForMobile {
    display: none;
  }
}
#concertPage .semesterDisplay {
  border-radius: 20px;
  background-color: rgb(238, 238, 238);
  border: 2px solid rgb(130, 130, 130);
  margin: 20px;
}
#concertPage .semesterDisplay .semesterOuterRow {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  padding: 10px;
}
#concertPage .semesterDisplay .semesterOuterRow p {
  width: 33%;
}
@media (max-width: 800px) {
  #concertPage .semesterDisplay .semesterOuterRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
#concertPage .semesterDisplay .semesterInnerRow {
  margin: 20px;
}
#concertPage .semesterDisplay .semesterInnerRow .shareButton {
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  #concertPage .semesterDisplay .semesterInnerRow {
    margin: 10px;
  }
}
#concertPage .semesterDisplay .groupedConcertRow {
  border: 2px solid black;
  border-radius: 10px;
  background-color: rgb(66, 66, 66);
}
#concertPage .semesterDisplay .groupedConcertRow .outerGroupedConcert {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: white;
  padding-left: 5px;
}
#concertPage .semesterDisplay .groupedConcertRow .innerGroupedConcert {
  padding: 3px;
}
@media (max-width: 600px) {
  #concertPage .semesterDisplay {
    margin: 5px;
  }
}
#concertPage .newestConcert {
  background-color: #F47920;
  padding: 4px;
  border-radius: 15px;
}
#concertPage .newestConcert > span {
  margin-left: 10px;
}
#concertPage #talkingState {
  width: fit-content;
}
#concertPage #talkingState p {
  padding: 5px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid hsl(207, 100%, 10%);
}
#concertPage #talkingState .showing {
  background-color: hsl(207, 100%, 80%);
}
#concertPage #talkingState .hiding {
  background-color: gray;
  color: white;
}

#techCheckArea {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
  background-color: black;
  color: white;
}
#techCheckArea .combinedConcertAndForm {
  background-color: white;
  padding: 5px;
  margin: 3px;
  border: 3px solid black;
  border-radius: 20px;
  color: black;
}
#techCheckArea .combinedConcertAndForm .reportPopup {
  display: block;
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 163px);
  width: 300px;
  height: 340px;
  background: linear-gradient(180deg, hsl(207, 100%, 90%), hsl(207, 100%, 70%));
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
}
#techCheckArea .combinedConcertAndForm .reportPopup textarea {
  width: 95%;
  height: 40px;
}
#techCheckArea .combinedConcertAndForm .outerRow {
  display: grid;
  grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
  grid-template-areas: "g a b c d e f";
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  user-select: none;
  overflow: hidden;
  gap: 5px;
  background-color: rgb(235, 235, 235);
}
@media (max-width: 800px) {
  #techCheckArea .combinedConcertAndForm .outerRow {
    grid-template-areas: "a b" "c d" "e h" "g f";
    grid-template-columns: 1fr 1fr;
  }
}
#techCheckArea .combinedConcertAndForm .outerRow .outerButtons {
  grid-area: g;
}
#techCheckArea .combinedConcertAndForm .outerRow .outerButtons .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#techCheckArea .combinedConcertAndForm .outerRow .outerButtons .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#techCheckArea .combinedConcertAndForm .outerRow .outerInfoCheck {
  align-items: center;
  margin-top: 10px;
  grid-area: f;
}
#techCheckArea .combinedConcertAndForm .outerRow .outerInfoCheck p {
  padding: 0 7px 0 7px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0;
  width: 60px;
}
#techCheckArea .combinedConcertAndForm .outerRow .outerInfoCheck .greenBox {
  background-color: rgb(126, 195, 126);
}
#techCheckArea .combinedConcertAndForm .outerRow .outerInfoCheck .redBox {
  background-color: rgb(245, 134, 134);
}
#techCheckArea .combinedConcertAndForm .outerRow:hover {
  background-color: lightgray;
  cursor: pointer;
}
#techCheckArea .combinedConcertAndForm .innerRow {
  background-color: rgb(239, 239, 239);
  cursor: default;
  border: 2px dashed black;
  border-radius: 10px;
  padding: 5px;
}
#techCheckArea .combinedConcertAndForm .innerRow .playTracksRow {
  display: grid;
  grid-template-columns: 150px 2fr 2fr 2fr 80px;
  background-color: white;
  border-style: groove;
  align-items: center;
}
#techCheckArea .combinedConcertAndForm .innerRow .playTracksRow div {
  display: flex;
  padding: 0px 5px;
}
#techCheckArea .combinedConcertAndForm .innerRow .playTracksRow div .clickableButton {
  height: 20px;
  width: 20px;
  margin: 20px 0;
  margin-right: 20px;
  cursor: pointer;
}
#techCheckArea .combinedConcertAndForm .innerRow .playTracksRow input {
  width: 15px;
}
@media (max-width: 800px) {
  #techCheckArea .combinedConcertAndForm .innerRow .playTracksRow {
    display: flex;
    flex-wrap: wrap;
  }
}
#techCheckArea .combinedConcertAndForm .innerRow .talking {
  background-color: rgb(218, 218, 255);
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "a b" "c b";
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .shareArea {
  margin-left: 10px;
  grid-area: a;
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .shareArea button {
  margin-bottom: 0;
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .shareArea img {
  border: 1px solid rgb(164, 164, 164);
  height: 30px;
  cursor: pointer;
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .innerRowTopButtonsRow {
  margin: 10px;
  display: flex;
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .innerRowTopButtonsRow .innerRowTopButtons {
  padding: 5px;
  background-color: #003057;
  color: #F47920;
  border: 2px solid black;
  border-radius: 5px;
  margin: 3px;
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .innerRowTopButtonsRow :link, #techCheckArea .combinedConcertAndForm .innerRow .infoArea .innerRowTopButtonsRow :visited {
  text-decoration: none;
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .performerArea {
  grid-area: b;
  align-self: stretch;
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .performerArea .performersList {
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid gray;
  margin-bottom: 10px;
  padding-left: 5px;
}
#techCheckArea .combinedConcertAndForm .innerRow .infoArea .performerArea .performersList span {
  display: block;
  padding: 2px;
}
@media (max-width: 800px) {
  #techCheckArea .combinedConcertAndForm .innerRow .infoArea .performerArea {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min(1fr, 50px);
  }
}
@media (max-width: 500px) {
  #techCheckArea .combinedConcertAndForm .innerRow .infoArea {
    grid-template-columns: 1fr;
    grid-template-areas: "b" "a" "c";
  }
}
#techCheckArea .combinedConcertAndForm .innerRow .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#techCheckArea .combinedConcertAndForm .innerRow .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#techCheckArea .combinedConcertAndForm .innerRow .indivConcertDescription {
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
}
#techCheckArea .combinedConcertAndForm .techCheckRow:hover {
  background-color: white;
  cursor: default;
}
#techCheckArea .combinedConcertAndForm .techCheckRow {
  display: grid;
  grid-template-columns: 1fr 1fr 5fr;
  border: 3px solid gray;
  border-radius: 15px;
  margin: 3px;
  padding: 6px;
}
#techCheckArea .combinedConcertAndForm .techCheckRow input {
  width: 90%;
}
#techCheckArea .techCheckInputForm {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

#calendarPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#calendarPage .ConcertHall {
  background-color: #add8e6;
  border-radius: 5px;
}
#calendarPage .RecitalHall {
  background-color: #f08080;
  border-radius: 5px;
}
#calendarPage .BlackBox {
  background-color: #d3d3d3;
  border-radius: 5px;
}
#calendarPage .Theatre {
  background-color: #acf2ac;
  border-radius: 5px;
}
#calendarPage .Other {
  background-color: black;
  border-radius: 5px;
  color: white;
}
#calendarPage #forDesktopUsers div.fc-daygrid-event-harness span {
  text-wrap: wrap;
}
@media (max-width: 1000px) {
  #calendarPage #forDesktopUsers {
    display: none;
  }
}
#calendarPage #forMobileUsers {
  display: none;
}
#calendarPage #forMobileUsers tr.fc-event:hover {
  color: black;
}
@media (max-width: 1000px) {
  #calendarPage #forMobileUsers {
    display: block;
  }
}
#calendarPage #calendarRequest {
  margin-top: 15px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 0 0 5px 5px;
  width: 50%;
}
@media (max-width: 700px) {
  #calendarPage #calendarRequest {
    width: 100%;
  }
}

#techPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#techPage #fullButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 75px;
  width: 90%;
  gap: 10px;
}
#techPage #fullButtons a {
  text-decoration: none;
}
#techPage #fullButtons a div {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: hsl(25, 91%, 70%);
  border: 5px solid hsl(25, 91%, 30%);
  border-radius: 20px;
  height: 95%;
  color: black;
  font-size: 20pt;
}
@media (max-width: 800px) {
  #techPage #fullButtons a div {
    font-size: 15pt;
  }
}

#lessonPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#lessonPage #requestForm {
  border: 1px solid black;
  border-radius: 3px;
  padding: 8px;
  margin-top: 50px;
}
#lessonPage #requestForm #lessonRequestForm {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
#lessonPage #requestForm #lessonRequestForm textarea, #lessonPage #requestForm #lessonRequestForm select {
  padding: 10px;
  margin: 3px;
  margin-bottom: 10px;
}
#lessonPage #requestForm #lessonRequestForm #tag .bolded {
  font-weight: bold;
}
#lessonPage .lessons {
  padding: 10px;
  background-color: hsl(207, 100%, 90%);
  border: 2px solid black;
  border-radius: 20px;
}
#lessonPage .lessons .outerRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  gap: 5px;
}
@media (max-width: 800px) {
  #lessonPage .lessons .outerRow {
    grid-template-columns: 1fr 2.5fr;
  }
}
#lessonPage .lessons .innerRow {
  display: flex;
  justify-content: center;
}
#lessonPage .lessons iframe {
  border-radius: 20px;
}
#lessonPage .lessons .personWhoSubmitted {
  font-size: large;
}
#lessonPage .lessons .youtubeVideo {
  border: none;
}
@media (max-width: 800px) {
  #lessonPage .lessons .youtubeVideo {
    display: none;
  }
}
#lessonPage .lessons .mobileYouTubeVideo {
  display: none;
}
@media (max-width: 800px) {
  #lessonPage .lessons .mobileYouTubeVideo {
    display: block;
    border: none;
  }
}

#articlesPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}

#productionPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}

#adminProgressPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#adminProgressPage #inProgressItems {
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#adminProgressPage #inProgressItems .inProgressItem {
  background-color: lightyellow;
  border: 1px solid black;
  border-radius: 2px;
  padding: 2px;
  margin: 4px;
}
#adminProgressPage #inProgressItems .inProgressItem button {
  margin-left: 10px;
}
#adminProgressPage #inProgressForm {
  margin-top: 20px;
}
#adminProgressPage #inProgressForm input {
  width: 200px;
}

#updateConcertPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#updateConcertPage #concertImport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
}
#updateConcertPage #concertImport #fileTitle, #updateConcertPage #concertImport #numberOfTracks {
  width: fill;
}
#updateConcertPage #concertImport #tagCheckboxImportForm {
  background-color: rgb(208, 208, 208);
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
  margin-left: 10px;
  border: 1px solid rgb(208, 208, 208);
  border-radius: 10px;
}
#updateConcertPage #concertImport #tagCheckboxImportForm .individualTags {
  display: inline;
  border: 2px solid black;
  border-radius: 5px;
  color: white;
  background-color: rgb(56, 56, 56);
  padding: 3px;
  margin: 5px;
  user-select: none;
  height: fit-content;
}
#updateConcertPage #concertImport #tagCheckboxImportForm .tagCheckboxImport {
  transform: scale(1.5);
}
#updateConcertPage #concertImport label, #updateConcertPage #concertImport p {
  justify-self: center;
  padding: 5px;
}
#updateConcertPage #concertImport #bottomCheckboxes {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
#updateConcertPage #concertImport #bottomCheckboxes div {
  user-select: none;
}
#updateConcertPage #concertImport #trackNumberButton {
  margin-bottom: 10px;
}
#updateConcertPage #concertImport #peopleSection, #updateConcertPage #concertImport #mainConcertInfoForm {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2px;
  margin: 10px;
}
#updateConcertPage #concertImport #peopleSection div input, #updateConcertPage #concertImport #peopleSection div textarea, #updateConcertPage #concertImport #mainConcertInfoForm div input, #updateConcertPage #concertImport #mainConcertInfoForm div textarea {
  width: 100%;
}
#updateConcertPage #concertImport #peopleSection label, #updateConcertPage #concertImport #mainConcertInfoForm label {
  user-select: none;
}
#updateConcertPage #concertImport #peopleSection #selectForms, #updateConcertPage #concertImport #mainConcertInfoForm #selectForms {
  display: flex;
  flex-wrap: nowrap;
}
#updateConcertPage #concertImport #peopleSection #selectForms .selectForm, #updateConcertPage #concertImport #mainConcertInfoForm #selectForms .selectForm {
  width: 30%;
  margin-right: 3px;
}
#updateConcertPage #concertImport #peopleSection #completedPerformersArea, #updateConcertPage #concertImport #mainConcertInfoForm #completedPerformersArea {
  margin: 2px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 2px;
  max-height: 200px;
  width: fit-content;
  overflow: hidden;
  overflow-y: auto;
}
#updateConcertPage #concertImport #formsArray .trackFormElement {
  padding: 10px;
  background-color: rgb(208, 208, 208);
  display: grid;
  grid-template-columns: 140px 3fr 80px 2fr 90px 0.5fr;
}
#updateConcertPage #concertImport #redDeleteButton {
  background-color: red;
  margin-top: 30px;
  color: white;
}
#updateConcertPage #invalidPara {
  background-color: rgb(255, 108, 108);
  margin-top: 10px;
}

#pipelinePage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#pipelinePage #recentConcerts {
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  margin-top: 20px;
}
#pipelinePage #recentConcerts #outerInfo {
  border: 1px solid black;
  border-radius: 10px;
  background-color: hsl(0, 0%, 90%);
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
#pipelinePage #recentConcerts #outerInfo p {
  margin-left: 10px;
}
#pipelinePage #recentConcerts #innerInfo {
  padding: 5px;
  border: 1px solid black;
  border-radius: 10px;
}
#pipelinePage #recentConcerts #innerInfo h2 {
  margin-bottom: 5px;
}
#pipelinePage #recentConcerts #innerInfo p {
  margin: 0;
  width: 70%;
  display: flex;
  justify-content: space-between;
}
#pipelinePage #recentConcerts #innerInfo p .separator {
  align-self: flex-end;
}
@media (max-width: 800px) {
  #pipelinePage #recentConcerts #innerInfo p {
    display: block;
  }
}
#pipelinePage #inProgressArea {
  border: 2px solid hsl(25, 91%, 20%);
  border-radius: 10px;
  background-color: hsl(25, 91%, 80%);
  padding: 5px;
  margin-bottom: 20px;
}
#pipelinePage #inProgressArea .inProgressItem {
  margin-top: 4px;
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px;
}
#pipelinePage #inProgressArea b {
  font-size: 20pt;
}
@media (max-width: 800px) {
  #pipelinePage #inProgressArea {
    grid-template-columns: 1fr 1fr;
  }
}
#pipelinePage #uncheckedArea {
  background-color: hsl(207, 100%, 80%);
  padding: 5px;
  border: 2px solid hsl(207, 100%, 20%);
  border-radius: 10px;
}
#pipelinePage #uncheckedArea .uncheckedConcert {
  margin-top: 4px;
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px;
}
#pipelinePage #uncheckedArea b {
  font-size: 20pt;
}

#playlistGenerationPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert {
  border: 2px solid hsl(207, 100%, 10%);
  border-radius: 10px;
  width: 90%;
  margin-bottom: 3px;
  cursor: pointer;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert .shortenedConcertInfo {
  width: 90%;
  display: grid;
  grid-template-columns: 3.5fr 0.5fr 1fr;
  padding-left: 10px;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert .innerShortenedConcertInfo {
  cursor: default;
  margin-bottom: 5px;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert .innerShortenedConcertInfo .shortenedIndividualTracks {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr;
  align-items: center;
  border: 1px solid hsl(25, 91%, 20%);
  border-radius: 10px;
  margin: 0 10px;
  padding-left: 5px;
  height: 50px;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert .innerShortenedConcertInfo .shortenedIndividualTracks .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert .innerShortenedConcertInfo .shortenedIndividualTracks .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert .innerShortenedConcertInfo .shortenedIndividualTracks p {
  margin: 0;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert .innerShortenedConcertInfo .shortenedIndividualTracks button {
  width: fit-content;
  height: fit-content;
}
#playlistGenerationPage #concertDisplayArea .shortenedConcert .innerShortenedConcertInfo .talking {
  background-color: rgb(218, 218, 255);
}
#playlistGenerationPage #playlistDisplayArea #playlistOpenArea #playlistDisplay {
  padding-top: 10px;
}
#playlistGenerationPage #playlistDisplayArea #playlistOpenArea #playlistDisplay .centeredInputs {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
#playlistGenerationPage #playlistDisplayArea #playlistOpenArea #playlistDisplay .centeredInputs input, #playlistGenerationPage #playlistDisplayArea #playlistOpenArea #playlistDisplay .centeredInputs textarea {
  width: 60%;
  margin-left: 10px;
}
#playlistGenerationPage #playlistDisplayArea #playlistOpenArea #playlistDisplay .playlistListItem {
  display: grid;
  grid-template-columns: 1fr 100px 60px 120px;
}
#playlistGenerationPage #playlistDisplayArea #playlistOpenArea #playlistDisplay .playlistListItem button {
  background-color: rgb(172, 0, 0);
  color: white;
}

#accountPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#accountPage #emailSent {
  color: red;
}
#accountPage .debugButtons {
  cursor: pointer;
}
#accountPage .levelDescriptions {
  padding-left: 15px;
}
#accountPage #upgradeUser {
  width: 50%;
  border: solid 2px rgb(167, 167, 167);
  border-radius: 10px;
  padding: 15px;
}
#accountPage #upgradeUser .centeredInputs {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

#largeButtonsPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#largeButtonsPage #fullButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px;
  width: 90%;
  gap: 10px;
}
#largeButtonsPage #fullButtons a {
  text-decoration: none;
}
#largeButtonsPage #fullButtons a, #largeButtonsPage #fullButtons div {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: hsl(25, 91%, 70%);
  border: 5px solid hsl(25, 91%, 30%);
  border-radius: 20px;
  height: 95%;
  color: black;
  font-size: 20pt;
  cursor: pointer;
}

#finishSignInPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}

#workPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#workPage #fullButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 75px;
  width: 90%;
  gap: 10px;
}
#workPage #fullButtons a {
  text-decoration: none;
}
#workPage #fullButtons a div {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: hsl(25, 91%, 70%);
  border: 5px solid hsl(25, 91%, 30%);
  border-radius: 20px;
  height: 95%;
  color: black;
  font-size: 20pt;
}
@media (max-width: 800px) {
  #workPage #fullButtons a div {
    font-size: 15pt;
  }
}

#announcementPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#announcementPage #calendarSection {
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
}

#userList {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#userList #userIndex {
  border: 2px solid black;
  border-radius: 5px;
}
#userList #userIndex .userRow {
  background-color: hsl(207, 100%, 90%);
  display: grid;
  grid-template-columns: 1.5fr 1fr 100px 50px;
  gap: 3px;
  padding: 5px 0 0 10px;
  border: 1px solid white;
  border-radius: 3px;
}

#sherrysPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#sherrysPage #concertDisplay .individualConcert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 3px solid hsl(207, 100%, 30%);
  border-radius: 10px;
  padding: 4px;
  margin: 3px;
  background-color: hsl(207, 100%, 90%);
}
#sherrysPage #concertDisplay .individualConcert .title {
  width: 30%;
  font-size: large;
  font-weight: 500;
  margin-right: 5px;
}

#scoreLibraryPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#scoreLibraryPage #introText {
  width: 95%;
}
#scoreLibraryPage #introText .individualScore {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: 95%;
  margin: 2px;
  padding: 3px;
  border: 2px solid black;
  border-radius: 10px;
  background-color: hsl(207, 100%, 70%);
}
#scoreLibraryPage #introText .individualScore p {
  margin: 1px 2px;
  padding-right: 5px;
  border-right: 1.5px solid hsl(207, 100%, 20%);
  background-color: inherit;
}
#scoreLibraryPage #introText .individualScore p span {
  background-color: inherit;
}
#scoreLibraryPage #introText .individualScore button {
  width: 150px;
  justify-self: center;
  align-self: center;
  background-color: rgb(240, 240, 240);
}
@media (max-width: 800px) {
  #scoreLibraryPage #introText .individualScore {
    grid-template-columns: 1fr;
  }
  #scoreLibraryPage #introText .individualScore p {
    border-right: none;
  }
}
#scoreLibraryPage #scoreCollection .individualScore {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: 95%;
  margin: 2px;
  padding: 3px;
  border: 2px solid black;
  border-radius: 10px;
  background-color: hsl(207, 100%, 70%);
}
#scoreLibraryPage #scoreCollection .individualScore p {
  margin: 1px 2px;
  padding-right: 5px;
  border-right: 1.5px solid hsl(207, 100%, 20%);
  background-color: inherit;
}
#scoreLibraryPage #scoreCollection .individualScore p span {
  background-color: inherit;
}
#scoreLibraryPage #scoreCollection .individualScore button {
  width: 150px;
  justify-self: center;
  align-self: center;
  background-color: rgb(240, 240, 240);
}
@media (max-width: 800px) {
  #scoreLibraryPage #scoreCollection .individualScore {
    grid-template-columns: 1fr;
  }
  #scoreLibraryPage #scoreCollection .individualScore p {
    border-right: none;
  }
}
#scoreLibraryPage #scoreCollection :nth-child(even) {
  background-color: hsl(207, 100%, 90%);
}
#scoreLibraryPage #scoreCollection .tableHeader {
  background-color: hsl(207, 100%, 20%);
  color: white;
}
#scoreLibraryPage #scoreCollection .tableHeader p {
  border-right: 1.5px solid hsl(207, 100%, 80%);
}
#scoreLibraryPage #inputForm {
  display: grid;
  grid-template-columns: 110px 1fr 120px 2fr;
  gap: 7px;
}
@media (max-width: 800px) {
  #scoreLibraryPage #inputForm {
    grid-template-columns: 120px 1fr;
  }
}
#scoreLibraryPage #filterSelection {
  margin: 10px 0;
}
#scoreLibraryPage #filterSelection p {
  cursor: pointer;
  user-select: none;
  width: 60px;
  padding: 8px;
  margin: 0;
  align-self: center;
  border: 2px solid black;
  border-radius: 20px;
  background-color: hsl(207, 100%, 50%);
}
#scoreLibraryPage #filterSelection #filterKeyContainer {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid black;
  border-radius: 3px;
}
#scoreLibraryPage #filterSelection #filterKeyContainer .filterKey {
  user-select: none;
  background-color: hsl(25, 91%, 80%);
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px;
  margin: 1.5px;
}
#scoreLibraryPage #filterSelection #filterKeyContainer .isSelected {
  background-color: hsl(207, 100%, 80%);
}

#playlistPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#playlistPage #fullButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 75px;
  width: 90%;
  gap: 10px;
}
#playlistPage #fullButtons a {
  text-decoration: none;
}
#playlistPage #fullButtons a div {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: hsl(25, 91%, 70%);
  border: 5px solid hsl(25, 91%, 30%);
  border-radius: 20px;
  height: 95%;
  color: black;
  font-size: 20pt;
}
@media (max-width: 800px) {
  #playlistPage #fullButtons a div {
    font-size: 15pt;
  }
}
#playlistPage .individualPlaylist {
  border: 1px solid black;
  border-radius: 10px;
  padding-bottom: 5px;
  background-color: hsl(207, 100%, 90%);
}
#playlistPage .individualPlaylist .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#playlistPage .individualPlaylist .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#playlistPage .individualPlaylist .outerPlaylistOption {
  display: flex;
  justify-content: space-around;
  padding: 5px;
  cursor: pointer;
}
#playlistPage .individualPlaylist .outerPlaylistOption .title {
  font-weight: 700;
}
#playlistPage .individualPlaylist .innerPlaylistOption {
  padding-left: 3px;
}
#playlistPage .individualPlaylist .innerPlaylistOption .buttonArea {
  display: flex;
}
#playlistPage .individualPlaylist .innerPlaylistOption .individualTracks {
  background-color: white;
  display: grid;
  grid-template-columns: 40px 40px 1fr 100px 30px;
  grid-template-rows: 50px;
  border: 1px solid black;
  border-radius: 10px;
  gap: 5px;
  margin: 0 5px;
  align-items: center;
}
#playlistPage .individualPlaylist .innerPlaylistOption .individualTracks .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#playlistPage .individualPlaylist .innerPlaylistOption .individualTracks .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#playlistPage .individualPlaylist .innerPlaylistOption .individualTracks img {
  width: 20px;
}

#adminMinorUpdates {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#adminMinorUpdates #renderedObjects {
  margin-top: 10px;
}
#adminMinorUpdates #renderedObjects .calendarOptions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 90px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}
#adminMinorUpdates #renderedObjects .calendarOptions p {
  margin: 0;
}
#adminMinorUpdates #renderedObjects .semesterRow {
  width: 500px;
  display: grid;
  grid-template-columns: 150px 120px 110px 100px;
  border: 1px solid black;
  padding-left: 10px;
}
#adminMinorUpdates #renderedObjects .directorMessages {
  border: 1px solid black;
  border-radius: 8px;
  padding: 3px;
}
#adminMinorUpdates #renderedObjects .directorMessages p {
  padding: 0;
  margin: 0;
}

#directorsPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#directorsPage #pages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 400px 500px 400px;
  grid-template-areas: "a b" "c c" "d d";
  gap: 5px;
}
#directorsPage #pages .extraPage {
  overflow: hidden;
  overflow-y: auto;
  border: 2px solid black;
  border-radius: 6px;
}
#directorsPage #pages .extraPage #pipelinePage {
  padding-bottom: 10px;
}
#directorsPage #pages .extraPage #calendarRequest {
  padding: 10px;
}
#directorsPage #pages .spreadsheet {
  grid-area: c;
}
#directorsPage #pages .requestForm {
  grid-area: d;
  padding: 10px;
}
#directorsPage #pages .requestForm input, #directorsPage #pages .requestForm textarea {
  width: calc(100% - 5px);
}
#directorsPage #pages .requestForm textarea {
  height: 120px;
}

#searchPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#searchPage #concertArea {
  margin-top: 10px;
}
#searchPage #concertArea .reportPopup {
  display: block;
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 163px);
  width: 300px;
  height: 340px;
  background: linear-gradient(180deg, hsl(207, 100%, 90%), hsl(207, 100%, 70%));
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
}
#searchPage #concertArea .reportPopup textarea {
  width: 95%;
  height: 40px;
}
#searchPage #concertArea .outerRow {
  display: grid;
  grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
  grid-template-areas: "g a b c d e f";
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  user-select: none;
  overflow: hidden;
  gap: 5px;
  background-color: rgb(235, 235, 235);
}
@media (max-width: 800px) {
  #searchPage #concertArea .outerRow {
    grid-template-areas: "a b" "c d" "e h" "g f";
    grid-template-columns: 1fr 1fr;
  }
}
#searchPage #concertArea .outerRow .outerButtons {
  grid-area: g;
}
#searchPage #concertArea .outerRow .outerButtons .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#searchPage #concertArea .outerRow .outerButtons .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#searchPage #concertArea .outerRow .outerInfoCheck {
  align-items: center;
  margin-top: 10px;
  grid-area: f;
}
#searchPage #concertArea .outerRow .outerInfoCheck p {
  padding: 0 7px 0 7px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0;
  width: 60px;
}
#searchPage #concertArea .outerRow .outerInfoCheck .greenBox {
  background-color: rgb(126, 195, 126);
}
#searchPage #concertArea .outerRow .outerInfoCheck .redBox {
  background-color: rgb(245, 134, 134);
}
#searchPage #concertArea .outerRow:hover {
  background-color: lightgray;
  cursor: pointer;
}
#searchPage #concertArea .innerRow {
  background-color: rgb(239, 239, 239);
  cursor: default;
  border: 2px dashed black;
  border-radius: 10px;
  padding: 5px;
}
#searchPage #concertArea .innerRow .playTracksRow {
  display: grid;
  grid-template-columns: 150px 2fr 2fr 2fr 80px;
  background-color: white;
  border-style: groove;
  align-items: center;
}
#searchPage #concertArea .innerRow .playTracksRow div {
  display: flex;
  padding: 0px 5px;
}
#searchPage #concertArea .innerRow .playTracksRow div .clickableButton {
  height: 20px;
  width: 20px;
  margin: 20px 0;
  margin-right: 20px;
  cursor: pointer;
}
#searchPage #concertArea .innerRow .playTracksRow input {
  width: 15px;
}
@media (max-width: 800px) {
  #searchPage #concertArea .innerRow .playTracksRow {
    display: flex;
    flex-wrap: wrap;
  }
}
#searchPage #concertArea .innerRow .talking {
  background-color: rgb(218, 218, 255);
}
#searchPage #concertArea .innerRow .infoArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "a b" "c b";
}
#searchPage #concertArea .innerRow .infoArea .shareArea {
  margin-left: 10px;
  grid-area: a;
}
#searchPage #concertArea .innerRow .infoArea .shareArea button {
  margin-bottom: 0;
}
#searchPage #concertArea .innerRow .infoArea .shareArea img {
  border: 1px solid rgb(164, 164, 164);
  height: 30px;
  cursor: pointer;
}
#searchPage #concertArea .innerRow .infoArea .innerRowTopButtonsRow {
  margin: 10px;
  display: flex;
}
#searchPage #concertArea .innerRow .infoArea .innerRowTopButtonsRow .innerRowTopButtons {
  padding: 5px;
  background-color: #003057;
  color: #F47920;
  border: 2px solid black;
  border-radius: 5px;
  margin: 3px;
}
#searchPage #concertArea .innerRow .infoArea .innerRowTopButtonsRow :link, #searchPage #concertArea .innerRow .infoArea .innerRowTopButtonsRow :visited {
  text-decoration: none;
}
#searchPage #concertArea .innerRow .infoArea .performerArea {
  grid-area: b;
  align-self: stretch;
}
#searchPage #concertArea .innerRow .infoArea .performerArea .performersList {
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid gray;
  margin-bottom: 10px;
  padding-left: 5px;
}
#searchPage #concertArea .innerRow .infoArea .performerArea .performersList span {
  display: block;
  padding: 2px;
}
@media (max-width: 800px) {
  #searchPage #concertArea .innerRow .infoArea .performerArea {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min(1fr, 50px);
  }
}
@media (max-width: 500px) {
  #searchPage #concertArea .innerRow .infoArea {
    grid-template-columns: 1fr;
    grid-template-areas: "b" "a" "c";
  }
}
#searchPage #concertArea .innerRow .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#searchPage #concertArea .innerRow .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#searchPage #concertArea .innerRow .indivConcertDescription {
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
}

#databaseEditing {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}

#stageBuilder {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#stageBuilder #threeDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  border-radius: 10px;
}
#stageBuilder .controlDisplay {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}
#stageBuilder .controlDisplay .clickableHeader {
  cursor: pointer;
  user-select: none;
  margin: 3px 0;
  text-align: center;
}
#stageBuilder #innerCloud {
  display: grid;
  grid-template-columns: 300px 1fr;
}
#stageBuilder #innerCloud .rgbDisplaySelector {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#stageBuilder #innerCloud .rgbDisplaySelector input[type=range] {
  width: 70px;
  margin: 0;
}
#stageBuilder #innerCloud .rgbDisplaySelector input[type=text] {
  width: 25px;
  margin: 0;
}
#stageBuilder #innerCloud .rgbDisplaySelector div {
  display: flex;
  align-items: center;
}
#stageBuilder #innerCloud .rgbDisplaySelector div input[type=range] {
  width: 70px;
  margin: 0;
}
#stageBuilder #innerCloud .rgbDisplaySelector div input[type=text] {
  width: 25px;
  margin: 0;
}
#stageBuilder #innerCloud #individualControls {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
#stageBuilder #innerCloud #individualControls input[type=color] {
  width: 100%;
}
#stageBuilder #innerCloud #groupedControls {
  overflow: scroll;
  overflow-x: hidden;
  height: 500px;
}
#stageBuilder #innerCloud #groupedControls .groupedControl {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
}
#stageBuilder #innerSpotlight {
  display: flex;
  justify-content: center;
}
#stageBuilder #innerSpotlight .spotlightDisplaySelector {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  height: 290px;
  width: 100px;
}
#stageBuilder #innerSpotlight .spotlightDisplaySelector label {
  text-align: center;
}
#stageBuilder #innerSpotlight .spotlightDisplaySelector .spotlightIntensitySelector {
  writing-mode: vertical-lr;
  direction: rtl;
  height: 100px;
}
#stageBuilder #innerSpotlight .spotlightDisplaySelector .spotlightAngleSelector {
  width: 70px;
}
#stageBuilder #innerSpotlight .spotlightDisplaySelector .spotlightTargetSelector {
  width: 70px;
  rotate: 180deg;
}
#stageBuilder #cloudHeightSelector {
  display: grid;
  grid-template-columns: 120px 120px 120px 120px;
}

#homeCalendarView {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#homeCalendarView .reportPopup {
  display: block;
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 163px);
  width: 300px;
  height: 340px;
  background: linear-gradient(180deg, hsl(207, 100%, 90%), hsl(207, 100%, 70%));
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
}
#homeCalendarView .reportPopup textarea {
  width: 95%;
  height: 40px;
}
#homeCalendarView .outerRow {
  display: grid;
  grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
  grid-template-areas: "g a b c d e f";
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  user-select: none;
  overflow: hidden;
  gap: 5px;
  background-color: rgb(235, 235, 235);
}
@media (max-width: 800px) {
  #homeCalendarView .outerRow {
    grid-template-areas: "a b" "c d" "e h" "g f";
    grid-template-columns: 1fr 1fr;
  }
}
#homeCalendarView .outerRow .outerButtons {
  grid-area: g;
}
#homeCalendarView .outerRow .outerButtons .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#homeCalendarView .outerRow .outerButtons .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#homeCalendarView .outerRow .outerInfoCheck {
  align-items: center;
  margin-top: 10px;
  grid-area: f;
}
#homeCalendarView .outerRow .outerInfoCheck p {
  padding: 0 7px 0 7px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0;
  width: 60px;
}
#homeCalendarView .outerRow .outerInfoCheck .greenBox {
  background-color: rgb(126, 195, 126);
}
#homeCalendarView .outerRow .outerInfoCheck .redBox {
  background-color: rgb(245, 134, 134);
}
#homeCalendarView .outerRow:hover {
  background-color: lightgray;
  cursor: pointer;
}
#homeCalendarView .innerRow {
  background-color: rgb(239, 239, 239);
  cursor: default;
  border: 2px dashed black;
  border-radius: 10px;
  padding: 5px;
}
#homeCalendarView .innerRow .playTracksRow {
  display: grid;
  grid-template-columns: 150px 2fr 2fr 2fr 80px;
  background-color: white;
  border-style: groove;
  align-items: center;
}
#homeCalendarView .innerRow .playTracksRow div {
  display: flex;
  padding: 0px 5px;
}
#homeCalendarView .innerRow .playTracksRow div .clickableButton {
  height: 20px;
  width: 20px;
  margin: 20px 0;
  margin-right: 20px;
  cursor: pointer;
}
#homeCalendarView .innerRow .playTracksRow input {
  width: 15px;
}
@media (max-width: 800px) {
  #homeCalendarView .innerRow .playTracksRow {
    display: flex;
    flex-wrap: wrap;
  }
}
#homeCalendarView .innerRow .talking {
  background-color: rgb(218, 218, 255);
}
#homeCalendarView .innerRow .infoArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "a b" "c b";
}
#homeCalendarView .innerRow .infoArea .shareArea {
  margin-left: 10px;
  grid-area: a;
}
#homeCalendarView .innerRow .infoArea .shareArea button {
  margin-bottom: 0;
}
#homeCalendarView .innerRow .infoArea .shareArea img {
  border: 1px solid rgb(164, 164, 164);
  height: 30px;
  cursor: pointer;
}
#homeCalendarView .innerRow .infoArea .innerRowTopButtonsRow {
  margin: 10px;
  display: flex;
}
#homeCalendarView .innerRow .infoArea .innerRowTopButtonsRow .innerRowTopButtons {
  padding: 5px;
  background-color: #003057;
  color: #F47920;
  border: 2px solid black;
  border-radius: 5px;
  margin: 3px;
}
#homeCalendarView .innerRow .infoArea .innerRowTopButtonsRow :link, #homeCalendarView .innerRow .infoArea .innerRowTopButtonsRow :visited {
  text-decoration: none;
}
#homeCalendarView .innerRow .infoArea .performerArea {
  grid-area: b;
  align-self: stretch;
}
#homeCalendarView .innerRow .infoArea .performerArea .performersList {
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid gray;
  margin-bottom: 10px;
  padding-left: 5px;
}
#homeCalendarView .innerRow .infoArea .performerArea .performersList span {
  display: block;
  padding: 2px;
}
@media (max-width: 800px) {
  #homeCalendarView .innerRow .infoArea .performerArea {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min(1fr, 50px);
  }
}
@media (max-width: 500px) {
  #homeCalendarView .innerRow .infoArea {
    grid-template-columns: 1fr;
    grid-template-areas: "b" "a" "c";
  }
}
#homeCalendarView .innerRow .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#homeCalendarView .innerRow .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#homeCalendarView .innerRow .indivConcertDescription {
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
}
#homeCalendarView #forDesktopUsers div.fc-daygrid-event-harness span {
  text-wrap: wrap;
  cursor: pointer;
}
@media (max-width: 1000px) {
  #homeCalendarView #forDesktopUsers {
    display: none;
  }
}
#homeCalendarView #forMobileUsers {
  display: none;
}
#homeCalendarView #forMobileUsers tr.fc-event:hover {
  color: black;
}
@media (max-width: 1000px) {
  #homeCalendarView #forMobileUsers {
    display: block;
  }
}

#communityPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#communityPage #fullButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 75px;
  width: 90%;
  gap: 10px;
}
#communityPage #fullButtons a {
  text-decoration: none;
}
#communityPage #fullButtons a div {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: hsl(25, 91%, 70%);
  border: 5px solid hsl(25, 91%, 30%);
  border-radius: 20px;
  height: 95%;
  color: black;
  font-size: 20pt;
}
@media (max-width: 800px) {
  #communityPage #fullButtons a div {
    font-size: 15pt;
  }
}

#compChallengePage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#compChallengePage .monthlyChallenge {
  border: 2px solid black;
  border-radius: 20px;
  padding: 7.5px;
}
#compChallengePage .monthlyChallenge .insideMonthlyChallenge {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}

#singleConcertPage {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}
#singleConcertPage .reportPopup {
  display: block;
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 163px);
  width: 300px;
  height: 340px;
  background: linear-gradient(180deg, hsl(207, 100%, 90%), hsl(207, 100%, 70%));
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
}
#singleConcertPage .reportPopup textarea {
  width: 95%;
  height: 40px;
}
#singleConcertPage .outerRow {
  display: grid;
  grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
  grid-template-areas: "g a b c d e f";
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  user-select: none;
  overflow: hidden;
  gap: 5px;
  background-color: rgb(235, 235, 235);
}
@media (max-width: 800px) {
  #singleConcertPage .outerRow {
    grid-template-areas: "a b" "c d" "e h" "g f";
    grid-template-columns: 1fr 1fr;
  }
}
#singleConcertPage .outerRow .outerButtons {
  grid-area: g;
}
#singleConcertPage .outerRow .outerButtons .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#singleConcertPage .outerRow .outerButtons .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#singleConcertPage .outerRow .outerInfoCheck {
  align-items: center;
  margin-top: 10px;
  grid-area: f;
}
#singleConcertPage .outerRow .outerInfoCheck p {
  padding: 0 7px 0 7px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0;
  width: 60px;
}
#singleConcertPage .outerRow .outerInfoCheck .greenBox {
  background-color: rgb(126, 195, 126);
}
#singleConcertPage .outerRow .outerInfoCheck .redBox {
  background-color: rgb(245, 134, 134);
}
#singleConcertPage .outerRow:hover {
  background-color: lightgray;
  cursor: pointer;
}
#singleConcertPage .innerRow {
  background-color: rgb(239, 239, 239);
  cursor: default;
  border: 2px dashed black;
  border-radius: 10px;
  padding: 5px;
}
#singleConcertPage .innerRow .playTracksRow {
  display: grid;
  grid-template-columns: 150px 2fr 2fr 2fr 80px;
  background-color: white;
  border-style: groove;
  align-items: center;
}
#singleConcertPage .innerRow .playTracksRow div {
  display: flex;
  padding: 0px 5px;
}
#singleConcertPage .innerRow .playTracksRow div .clickableButton {
  height: 20px;
  width: 20px;
  margin: 20px 0;
  margin-right: 20px;
  cursor: pointer;
}
#singleConcertPage .innerRow .playTracksRow input {
  width: 15px;
}
@media (max-width: 800px) {
  #singleConcertPage .innerRow .playTracksRow {
    display: flex;
    flex-wrap: wrap;
  }
}
#singleConcertPage .innerRow .talking {
  background-color: rgb(218, 218, 255);
}
#singleConcertPage .innerRow .infoArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "a b" "c b";
}
#singleConcertPage .innerRow .infoArea .shareArea {
  margin-left: 10px;
  grid-area: a;
}
#singleConcertPage .innerRow .infoArea .shareArea button {
  margin-bottom: 0;
}
#singleConcertPage .innerRow .infoArea .shareArea img {
  border: 1px solid rgb(164, 164, 164);
  height: 30px;
  cursor: pointer;
}
#singleConcertPage .innerRow .infoArea .innerRowTopButtonsRow {
  margin: 10px;
  display: flex;
}
#singleConcertPage .innerRow .infoArea .innerRowTopButtonsRow .innerRowTopButtons {
  padding: 5px;
  background-color: #003057;
  color: #F47920;
  border: 2px solid black;
  border-radius: 5px;
  margin: 3px;
}
#singleConcertPage .innerRow .infoArea .innerRowTopButtonsRow :link, #singleConcertPage .innerRow .infoArea .innerRowTopButtonsRow :visited {
  text-decoration: none;
}
#singleConcertPage .innerRow .infoArea .performerArea {
  grid-area: b;
  align-self: stretch;
}
#singleConcertPage .innerRow .infoArea .performerArea .performersList {
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid gray;
  margin-bottom: 10px;
  padding-left: 5px;
}
#singleConcertPage .innerRow .infoArea .performerArea .performersList span {
  display: block;
  padding: 2px;
}
@media (max-width: 800px) {
  #singleConcertPage .innerRow .infoArea .performerArea {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min(1fr, 50px);
  }
}
@media (max-width: 500px) {
  #singleConcertPage .innerRow .infoArea {
    grid-template-columns: 1fr;
    grid-template-areas: "b" "a" "c";
  }
}
#singleConcertPage .innerRow .pButton {
  background-color: gray;
  color: white;
  padding: 5px;
  margin: 5px;
  border: 2px solid gray;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}
#singleConcertPage .innerRow .pButton .pButtonImage {
  width: 100%;
  height: 100%;
}
#singleConcertPage .innerRow .indivConcertDescription {
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
}
#singleConcertPage #talkingState {
  width: fit-content;
}
#singleConcertPage #talkingState p {
  padding: 5px;
  cursor: pointer;
  user-select: none;
  border-radius: 20px;
  border: 1px solid hsl(207, 100%, 10%);
}
#singleConcertPage #talkingState .showing {
  background-color: hsl(207, 100%, 80%);
}
#singleConcertPage #talkingState .hiding {
  background-color: gray;
  color: white;
}

#auditTotals {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
}

#conventionPages {
  padding: 10px;
  grid-area: b;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 25%;
  padding-right: 10%;
}
#conventionPages img {
  max-height: 400px;
  display: block;
}
#conventionPages h1, #conventionPages h2, #conventionPages h3, #conventionPages h4 {
  margin: 10px 0;
}
#conventionPages p {
  margin: 14px 0;
}
#conventionPages .keybind {
  background-color: lightyellow;
  padding: 0 3px;
  margin: 0 2px;
  font-weight: 900;
  border: 1px solid black;
  border-radius: 5px;
}
#conventionPages .noUserSelect {
  user-select: none;
}
#conventionPages #keyBindsOpening {
  background-color: hsl(207, 100%, 90%);
  padding: 8px;
  border: 1px solid black;
  border-radius: 10px;
}
#conventionPages .dualColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
#conventionPages table thead > tr > td {
  background-color: black;
  color: white;
}
#conventionPages table td {
  border: 2px solid black;
  padding: 20px;
  text-align: center;
}