@use "Library";

@mixin import {
    #lessonPage {
        @include Library.mainPageFormat;
        #requestForm {
            border: 1px solid black;
            border-radius: 3px;
            padding: 8px;
            margin-top: 50px;
            #lessonRequestForm {
                    display: grid;
                    grid-template-rows: 1fr 1fr 1fr;
                textarea, select {
                    padding: 10px;
                    margin: 3px;
                    margin-bottom: 10px;
                }
                #tag {
                    .bolded {
                        font-weight: bold;
                    }
                }
            }
        }
        .lessons {
            padding: 10px;
            background-color: Library.$SC-color-blue-900;
            border: 2px solid black;
            border-radius: 20px;
            .outerRow {
                display: grid;
                grid-template-columns: 1fr 1fr 1.5fr;
                gap: 5px;
                @media (max-width: 800px) {
                    grid-template-columns: 1fr 2.5fr;
                }
            }
            .innerRow {
                display: flex;
                justify-content: center;
            }
            iframe {
                border-radius: 20px;
            }
            .personWhoSubmitted {
                font-size: large;
            }
            .youtubeVideo {
                border: none;
                @media (max-width: 800px){
                    display: none;
                }
            }
            .mobileYouTubeVideo {
                display: none;
                @media (max-width: 800px){
                    display: block;
                    border: none;
                }
            }
        }
    }
}