@use "Library";

@mixin import {
    #calendarPage {
        @include Library.mainPageFormat;
        @include Library.concertLocationColor;
        #forDesktopUsers {
            div.fc-daygrid-event-harness span{
                text-wrap: wrap;
            }
            @media (max-width: 1000px) {
                display: none;
            }
        }
        #forMobileUsers {
            display: none;
    
            tr.fc-event:hover {
                color: black;
            }
            @media (max-width: 1000px) {
                display: block;
            }
        }
        #calendarRequest {
            margin-top: 15px;
            border: 1px solid lightgray;
            border-radius: 10px;
            padding: 0 0 5px 5px;
            width: 50%; 
            @media (max-width: 700px){
                width: 100%;
            }
        }
    }
}