@use "Library";

@mixin import {
    #sideBar {
        padding-top: 0;
        height: 100vh;
        grid-area: a;
        background-color: Library.$SC-color-blue;
        ul {
            padding-left: 5px;
        }
        li {
            list-style-type: none;
            padding: 10px;
            margin: 0px 5px 5px 0px;
            background-color: Library.$SC-color-orange;
            border: 1px solid black;
            border-radius: 10px;
            align-self: center;
            justify-self: center;
            width: fill; // Fixed the odd issue of not all of them being the same width? 
            @media (max-width: 800px) {
                display: inline;
            }
        }
        :link, :visited {
            color: white;
            text-decoration: none;
        }
        .secondOrder {
            padding-left: 10px;
        }
        .navElement {
            display: flex;
            flex-direction: column;
            p {
                padding: 0;
                margin: 0;
            }
        }
        .expandedElements {
            position: absolute;
            animation: fadeIn 0.5s;
            animation-fill-mode: forwards;
            background-color: Library.$SC-color-blue;
            padding: 5px;
            padding-top: 7px;
            border: 2px solid Library.$SC-color-blue;
            border-radius: 10px;
            z-index: 20;
            .expandedLink {
                z-index: 10000;
            }
            @media (max-width: 800px) {
                display: none;
            }
        }
        img {
            padding-top: 10px;
            width: 100%;
            @media (max-width: 800px) {
                width: 50px;
                display: inline;
                padding-top: 5px;
            }
        }
        a {
            @media (max-width: 800px) {
                height: 50px;
            }
        }
        @media (max-width: 800px) {
            display: flex;
            flex-wrap: nowrap;
            height: 60px;
            overflow-x: auto;
            position: sticky;
            top: 0;
            width: 100%;
            overflow-y: hidden;
        }
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}