@use "Library";

@mixin import {
    #accountPage {
        @include Library.mainPageFormat;
        #emailSent {
            color: red;
        }
        .debugButtons {
            cursor: pointer;
        }
        .levelDescriptions {
            padding-left: 15px;
        }
        #upgradeUser {
            width: 50%;
            border: solid 2px rgb(167, 167, 167);
            border-radius: 10px;
            padding: 15px;
            .centeredInputs {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
            }
        }
    }
}