@use "Library";

@mixin import {
    #scoreLibraryPage {
        @include Library.mainPageFormat;

        #introText {
            width: 95%;
            @include individualScore();
        }

        #scoreCollection {
            @include individualScore();
            
            :nth-child(even) {
                background-color: Library.$SC-color-blue-900;
            }

            .tableHeader {
                background-color: Library.$SC-color-blue-200;
                color: white;

                p {
                    border-right: 1.5px solid Library.$SC-color-blue-800;
                }
            }
        }

        #inputForm {
            display: grid;
            grid-template-columns: 110px 1fr 120px 2fr;
            gap: 7px;

            @media (max-width: 800px) {
                grid-template-columns: 120px 1fr;
            }
        }

        #filterSelection {
            margin: 10px 0;

            p {
                cursor: pointer;
                user-select: none;
                width: 60px;
                padding: 8px;
                margin: 0;
                align-self: center;
                border: 2px solid black;
                border-radius: 20px;
                background-color: Library.$SC-color-blue-500;
            }

            #filterKeyContainer {
                display: flex;
                flex-wrap: wrap;
                border: 2px solid black;
                border-radius: 3px;

                .filterKey {
                    user-select: none;
                    background-color: Library.$SC-color-orange-800;
                    cursor: pointer;
                    border: 1px solid black;
                    border-radius: 5px;
                    padding: 2px;
                    margin: 1.5px;
                }

                .isSelected {
                    background-color: Library.$SC-color-blue-800;
                }
            }
        }
    }
}

@mixin individualScore {
    .individualScore {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        width: 95%;
        margin: 2px;
        padding: 3px;
        border: 2px solid black;
        border-radius: 10px;
        background-color: Library.$SC-color-blue-700;

        p {
            margin: 1px 2px;
            padding-right: 5px;
            border-right: 1.5px solid Library.$SC-color-blue-200;
            background-color: inherit;
            span {
                background-color: inherit;
            }
        }

        button {
            width: 150px;
            justify-self: center;
            align-self: center;
            background-color: rgb(240, 240, 240);
        }
    }

    @media (max-width: 800px) {
        .individualScore {
            grid-template-columns: 1fr;

            p {
                border-right: none;
            }
        }
    }
}