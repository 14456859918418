@use "Library";

@mixin import {
    #adminProgressPage {
        @include Library.mainPageFormat;
        #inProgressItems {
            border-radius: 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .inProgressItem {
                background-color: lightyellow;
                border: 1px solid black;
                border-radius: 2px;
                padding: 2px;
                margin: 4px;
                button {
                    margin-left: 10px;
                }
            }
        }
        #inProgressForm {
            margin-top: 20px;
            input { width: 200px; }
        }
    }
}