@use "Library"; 

@mixin import {
    #playlistGenerationPage {
        @include Library.mainPageFormat;
        display: grid;
        grid-template-columns: 1fr 1fr;
        #concertDisplayArea {
            .shortenedConcert {
                border: 2px solid Library.$SC-color-blue-100;
                border-radius: 10px;
                width: 90%;
                margin-bottom: 3px;
                cursor: pointer;
                .shortenedConcertInfo {
                    width: 90%;
                    display: grid;
                    grid-template-columns: 3.5fr 0.5fr 1fr;
                    padding-left: 10px;
                }
                .innerShortenedConcertInfo {
                    cursor: default;
                    margin-bottom: 5px;
                    .shortenedIndividualTracks {
                        display: grid;
                        grid-template-columns: 0.5fr 0.5fr 1fr 1fr;
                        align-items: center;
                        border: 1px solid Library.$SC-color-orange-200;
                        border-radius: 10px;
                        margin: 0 10px;
                        padding-left: 5px;
                        height: 50px;
                        @include Library.pButton;
                        p { margin: 0; }
                        button {
                            width: fit-content;
                            height: fit-content;
                        }
                    }
                    .talking {
                        background-color: Library.$Talking-Track;
                    }
                }
            }
        }
        #playlistDisplayArea {
            #playlistOpenArea {
                #playlistDisplay {
                    padding-top: 10px;
                    .centeredInputs {
                        display: flex;
                        align-items: center;
                        margin-bottom: 3px;
                        input, textarea {
                            width: 60%;
                            margin-left: 10px;
                        }
                    }
                    .playlistListItem {
                        display: grid;
                        grid-template-columns: 1fr 100px 60px 120px;
                        button {
                            background-color: rgb(172, 0, 0);
                            color: white;
                        }
                    }
                }
            }
        }
    }
}