@use "Library";

@mixin import {
    #announcementPage {
        @include Library.mainPageFormat;
        #calendarSection {
            border: 2px solid black;
            border-radius: 10px;
            padding: 10px;
        }
    }
}