@use "Library"; 

@mixin import {
    #playlistPage {
        @include Library.mainPageFormat;
        @include Library.halfButtons;
        .individualPlaylist {
            @include Library.pButton;
            border: 1px solid black;
            border-radius: 10px;
            padding-bottom: 5px;
            background-color: Library.$SC-color-blue-900;
            .outerPlaylistOption {
                display: flex;
                justify-content: space-around;
                padding: 5px;
                cursor: pointer;
                .title {
                    font-weight: 700;
                }
            }
            .innerPlaylistOption {
                padding-left: 3px;
                .buttonArea {
                    display: flex;
                }
                .individualTracks {
                    background-color: white;
                    @include Library.pButton;
                    display: grid;
                    grid-template-columns: 40px 40px 1fr 100px 30px;
                    grid-template-rows: 50px;
                    border: 1px solid black;
                    border-radius: 10px;
                    gap: 5px;
                    margin: 0 5px;
                    align-items: center;
                    img {
                        width: 20px;
                    }
                }
            }
        }
    }
}