@use "Library";

@mixin import {
    #conventionPages {
        @include Library.mainPageFormat;
        padding-right: 10%;

        img {
            max-height: 400px;
            display: block;
        }

        h1, h2, h3, h4 {
            margin: 10px 0;
        }
        p {margin: 14px 0;}

        .keybind {
            background-color: lightyellow;
            padding: 0 3px;
            margin: 0 2px;
            font-weight: 900;
            border: 1px solid black;
            border-radius: 5px;
        }

        .noUserSelect {
            user-select: none;
        }

        #keyBindsOpening {
            background-color: Library.$SC-color-blue-900;
            padding: 8px;
            border: 1px solid black;
            border-radius: 10px;
        }

        .dualColumn {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 5px;
        }

        table {
            thead>tr>td {
                background-color: black;
                color: white
            }

            td {
                border: 2px solid black;
                padding: 20px;
                text-align: center;
            }
        }
    }
}