@use "Library";

@mixin import {
    #sherrysPage {
        @include Library.mainPageFormat;
        #concertDisplay {
            .individualConcert {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 3px solid Library.$SC-color-blue-300;
                border-radius: 10px;
                padding: 4px;
                margin: 3px;
                background-color: Library.$SC-color-blue-900;
                .title {
                    width: 30%;
                    font-size: large;
                    font-weight: 500;
                    margin-right: 5px;
                }
            }
        }
    }
}