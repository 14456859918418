@use "Library"; @use "Home";

@mixin import {
    #homeCalendarView {
        @include Library.mainPageFormat;
        @include Home.individualConcertDisplay;

        #forDesktopUsers {
            div.fc-daygrid-event-harness span {
                text-wrap: wrap;
                cursor: pointer;
            }

            @media (max-width: 1000px) {
                display: none;
            }
        }

        #forMobileUsers {
            display: none;

            tr.fc-event:hover {
                color: black;
            }

            @media (max-width: 1000px) {
                display: block;
            }
        }
    }
}