@use "Library"; @use "ConcertImport";

@mixin import {
    #updateConcertPage {
        @include Library.mainPageFormat;
        @include ConcertImport.concertImportForm;
        #invalidPara {
            background-color: rgb(255, 108, 108);
            margin-top: 10px;
        }
    }
}