@use "Library"; @use "Home";

@mixin import {
    #techCheckArea {
        @include Library.mainPageFormat;
        background-color: black;
        color: white;
        .combinedConcertAndForm{
            background-color: white;
            padding: 5px;
            margin: 3px;
            border: 3px solid black;
            border-radius: 20px;
            @include Home.individualConcertDisplay;
            color: black;
            .techCheckRow:hover {
                background-color: white;
                cursor: default;
            }
            .techCheckRow {
                display: grid;
                grid-template-columns: 1fr 1fr 5fr;
                border: 3px solid gray;
                border-radius: 15px;
                margin: 3px;
                padding: 6px;
                input {
                    width: 90%;
                }
            }
        }
        .techCheckInputForm {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
    }
}