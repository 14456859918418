@use "Library"; @use "Popup"; 

@mixin import {
    #concertPage {
        @include Library.mainPageFormat;
        @include Library.halfButtons;
        #todayCalendarEvent {
            @include Library.concertLocationColor;
        }
        .concertRow {
            @include individualConcertDisplay();
        }
        .concertHeaderRow {
            display: grid;
            grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
            border: 2px solid rgb(58, 58, 58);
            border-radius: 10px;
            padding: 0px 5px;
            background-color: rgb(58, 58, 58);
            color: white;
            p {
                padding: 4px;
                margin: 0;
            }
            @media (max-width: 800px) {
                grid-template-columns: 1fr 1fr;
                .notForMobile {
                    display: none;
                }
            }
        }
        .semesterDisplay {
            border-radius: 20px;
            background-color: rgb(238, 238, 238);
            border: 2px solid rgb(130, 130, 130);
            margin: 20px;
            .semesterOuterRow {
                display: flex;
                justify-content: space-around;
                cursor: pointer;
                padding: 10px;
                p {
                    width: 33%;
                }
                @media (max-width: 800px){
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }
            .semesterInnerRow {
                margin: 20px;
                .shareButton {
                    margin-bottom: 20px;
                }
                @media (max-width: 600px) {
                    margin: 10px;
                }
            }
            .groupedConcertRow {
                border: 2px solid black;
                border-radius: 10px;
                background-color: rgb(66, 66, 66);
                .outerGroupedConcert {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    color: white;
                    padding-left: 5px;
                }
                .innerGroupedConcert {
                    padding: 3px;
                }
            }
            @media (max-width: 600px) {
                margin: 5px;
            }
        }
        .newestConcert {
            background-color: Library.$SC-color-orange;
            padding: 4px;
            border-radius: 15px;
            >span {
                margin-left: 10px;
            }
        }
        #talkingState {
            width: fit-content;
            p {
                padding: 5px;
                cursor: pointer;
                border-radius: 20px;
                border: 1px solid Library.$SC-color-blue-100;
            }
            .showing {background-color: Library.$SC-color-blue-800;}
            .hiding {background-color: gray; color: white;}
        }
    }
}

@mixin individualConcertDisplay {
    @include Popup.import;
    .outerRow{
        display: grid;
        grid-template-columns: 70px 1.6fr 1.3fr 1fr 0.7fr 0.7fr 100px 2fr;
        grid-template-areas: "g a b c d e f";
        border: 2px solid black;
        border-radius: 10px;
        padding: 5px;
        user-select: none;
        overflow: hidden;
        gap: 5px;
        background-color: rgb(235, 235, 235);
        @media (max-width: 800px) {
            grid-template-areas: "a b"
                                 "c d"
                                 "e h"
                                 "g f";
            grid-template-columns: 1fr 1fr;
        }
        .outerButtons {
            @include Library.pButton;
            grid-area: g;
        }
        .outerInfoCheck {
            align-items: center;
            margin-top: 10px;
            grid-area: f;
            p {
                padding: 0 7px 0 7px;
                border: 1px solid black;
                border-radius: 5px;
                margin: 0;
                width: 60px;
            }
            .greenBox {background-color: rgb(126, 195, 126);}
            .redBox {background-color: rgb(245, 134, 134);}
        }
    }
    .outerRow:hover {
        background-color: lightgray;
        cursor: pointer;
    }
    .innerRow {
        background-color: rgb(239, 239, 239);
        cursor: default;
        border: 2px dashed black;
        border-radius: 10px;
        padding: 5px;
        .playTracksRow {
            display: grid;
            grid-template-columns: 150px 2fr 2fr 2fr 80px;
            background-color: white;
            border-style: groove;
            align-items: center;
            div {
                display: flex;
                padding: 0px 5px;
                .clickableButton {
                    height: 20px;
                    width: 20px;
                    margin: 20px 0;
                    margin-right: 20px;
                    cursor: pointer;
                }
            }
            input {
                width: 15px;
            }
            @media (max-width: 800px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
        .talking {
            background-color: Library.$Talking-Track;
        }
        .infoArea {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas: "a b"
                                 "c b";
            .shareArea {
                margin-left: 10px;
                grid-area: a;
                button {
                    margin-bottom: 0;
                }
                img {
                    border: 1px solid rgb(164, 164, 164);
                    height: 30px;
                    cursor: pointer;
                }
            }
            .innerRowTopButtonsRow{
                margin: 10px;
                display: flex;
                .innerRowTopButtons {
                    padding: 5px;
                    background-color: Library.$SC-color-blue;
                    color: Library.$SC-color-orange;
                    border: 2px solid black;
                    border-radius: 5px;
                    margin: 3px;
                }
                :link, :visited {
                    text-decoration: none;
                }
            }
            .performerArea {
                grid-area: b;
                align-self: stretch;
                .performersList {
                    height: 150px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    border: 2px solid gray;
                    margin-bottom: 10px;
                    padding-left: 5px;
                    span {
                        display: block;
                        padding: 2px;
                    }
                }
                @media (max-width: 800px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr min(1fr, 50px);
                }
            }
            @media (max-width: 500px) {
                grid-template-columns: 1fr;
                grid-template-areas: "b"
                                     "a"
                                     "c";
            }
        }
        @include Library.pButton;
        .indivConcertDescription {
            margin: 10px;
            border: 1px solid black;
            border-radius: 5px;
            width: fit-content;
            padding: 5px;
        }
    }
}