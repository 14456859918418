// @use "custom"; File uses bootstrap and that isn't compiling.
@use "Library"; @use "ConcertImport"; @use "LowerThird"; @use "Sidebar";
@use "Admin"; @use "Home"; @use "TechCheckArea"; @use "Calendar";
@use "Tech"; @use "Lessons"; @use "Articles"; @use "Production";
@use "InProgress"; @use "UpdateConcert"; @use "Pipeline"; @use "PlaylistGeneration";
@use "Account"; @use "AdminLargeButtons"; @use "FinishSignIn"; @use "WorkPage";
@use "Announcements"; @use "UserList"; @use "Sherry"; @use "ScoreLibrary";
@use "Playlists"; @use "MinorUpdates"; @use "Directors"; @use "Popup";
@use "Search"; @use "DatabaseEditing"; @use "StageBuilder"; @use "HomeCalendarView";
@use "Community"; @use "CompChallenge"; @use "SingleConcertDisplay"; @use "AuditTotals";
@use "Conventions";

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    font-family: museo, serif;
    font-weight: 300;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#webpage {
    height: 100vh;
    grid-template-areas: "a b"
                         "c c";
    grid-template-columns: 120px 1fr;
    grid-template-rows: 85vh 15vh;
    display: grid;
    @media (max-width: 800px) {
        grid-template-areas: "c"
        "a"
        "b";
        grid-template-columns: 1fr;
        grid-template-rows: 110px 60px 10fr;
    }
    @include LowerThird.import;
    @include Sidebar.import; 
}

textarea, input, select, button {
    font-family: Museo;
}

#aboutPage {
    @include Library.mainPageFormat;
    #underline {
        text-decoration: underline;
        cursor: pointer;
    }
    #copied {
        color: green;
    }
}

#missingPage {
    @include Library.mainPageFormat();
}

@include ConcertImport.import;
@include Admin.import;
@include Home.import;
@include TechCheckArea.import; 
@include Calendar.import;
@include Tech.import;
@include Lessons.import;
@include Articles.import;
@include Production.import;
@include InProgress.import;
@include UpdateConcert.import;
@include Pipeline.import;
@include PlaylistGeneration.import;
@include Account.import;
@include AdminLargeButtons.import;
@include FinishSignIn.import;
@include WorkPage.import;
@include Announcements.import;
@include UserList.import;
@include Sherry.import;
@include ScoreLibrary.import;
@include Playlists.import;
@include MinorUpdates.import;
@include Directors.import;
@include Search.import;
@include DatabaseEditing.import;
@include StageBuilder.import;
@include HomeCalendarView.import;
@include Community.import;
@include CompChallenge.import;
@include SingleConcertDisplay.import;
@include AuditTotals.import;
@include Conventions.import;