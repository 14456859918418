@use "Library";

@mixin import {
    #userList {
        @include Library.mainPageFormat;
        #userIndex {
            border: 2px solid black;
            border-radius: 5px;
            .userRow {
                background-color: Library.$SC-color-blue-900;
                display: grid;
                grid-template-columns: 1.5fr 1fr 100px 50px;
                gap: 3px;
                padding: 5px 0 0 10px;
                border: 1px solid white;
                border-radius: 3px;
            }
        }
    }
}