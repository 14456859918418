@use "Library";

@mixin import {
    #stageBuilder {
        @include Library.mainPageFormat;

        #threeDisplay {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: black;
            border-radius: 10px;
        }

        .controlDisplay {
            .clickableHeader {
                cursor: pointer;
                user-select: none;
                margin: 3px 0;
                text-align: center;
            }

            border: 1px solid black;
            border-radius: 5px;
            padding: 5px;
        }

        #innerCloud {
            display: grid;
            // grid-template-rows: 200px 1fr;
            grid-template-columns: 300px 1fr;
            // grid-template-areas: "a a"
            //                      "b c";

            .rgbDisplaySelector {
                border: 1px solid black;
                border-radius: 10px;
                padding: 5px;
                width: 110px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include inputs();

                div {
                    display: flex;
                    align-items: center;
                    @include inputs();
                }
            }

            #individualControls {
                display: grid;
                grid-template-columns: repeat(7, 1fr);

                input[type="color"] {
                    width: 100%;
                }
            }

            #groupedControls {
                overflow: scroll;
                overflow-x: hidden;
                height: 500px;

                .groupedControl {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid black;
                    border-radius: 10px;

                }
            }

        }


        #innerSpotlight {
            display: flex;
            justify-content: center;

            .spotlightDisplaySelector {
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid black;
                border-radius: 10px;
                height: 290px;
                width: 100px;

                label {
                    text-align: center;
                }

                .spotlightIntensitySelector {
                    writing-mode: vertical-lr;
                    direction: rtl;
                    height: 100px;
                }

                .spotlightAngleSelector {
                    width: 70px;
                }

                .spotlightTargetSelector {
                    width: 70px;
                    rotate: 180deg;
                }
            }
        }

        #cloudHeightSelector {
            display: grid;
            grid-template-columns: 120px 120px 120px 120px;
        }
    }
}

@mixin inputs {
    input[type="range"] {
        width: 70px;
        margin: 0;
    }

    input[type="text"] {
        width: 25px;
        margin: 0;
    }
}