@use "Library";

@mixin import {
    #admin {
        @include Library.mainPageFormat;
        @include Library.halfButtons;
        #requestBlock {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            #calendarRequest {
            @include outerRequest();
                .calendarCheck {
                    @include innerRequest();
                }
            }
            #lessonRequest {
            @include outerRequest();
                .lessonCheck {
                    @include innerRequest();
                }
            }
            #userRequest {
            @include outerRequest();
                .userCheck {
                    @include innerRequest();
                }
            }
            #usernameRequest {
            @include outerRequest();
                .nameAccept {
                    @include innerRequest();
                }
            }
            #userReports {
            @include outerRequest();
                .userReport {
                    @include innerRequest();
                }
            }
        }
    }
}

@mixin outerRequest {
    border: 2px solid black;
    border-radius: 10px;
    padding-left: 5px;
    h2 {
        padding-left: 5px;
    }
}

@mixin innerRequest {
    margin: 0 7px 10px 0;
    padding: 10px;
    border: 2px solid darkgray;
    input, textarea {
        width: 100%;
    }
}